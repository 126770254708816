import { Box } from "@chakra-ui/react"
import type { FC, ReactNode } from "react"
import { useEffect, useState } from "react"

type Props = {
  children: ReactNode
}
const ClientOnly: FC<Props> = ({ children }) => {
  const [hasMounted, setHasMounted] = useState(false)

  useEffect(() => {
    setHasMounted(true)
  }, [])

  if (!hasMounted) {
    return null
  }

  return <Box>{children}</Box>
}

export default ClientOnly
