import { chainIdToChainReference } from "web3/helpers/transformers"
import chains, { ChainId } from "web3/constants/chains"
import type { Chain } from "query/graphql"
import { removeTrailingSlashes } from "common/helpers/utils"
import { getChainId } from "web3/helpers/chainId"
import { getChainByReference } from "web3/helpers/findChain"

/**
 * Retrieves the chain object based on the provided chainId.
 *
 * @param chainId The CAIP complient identifier of the chain.
 * @returns The corresponding chain object.
 * @throws If the chainId is not supported.
 */
export const getChain = (chainId: string): Chain => {
  // Type guard function to check if the chainId is valid
  const isChainIdSupported = (id: string): id is ChainId => {
    return Object.values(ChainId).some((id) => id === chainId)
  }

  if (isChainIdSupported(chainId)) {
    return chains[chainId]
  }

  throw new Error(`Chain with id ${chainId} is not supported.`)
}

// Extracts chain ID from safe and returns an undefined value instead of throwing an error
export function findChainBySafeId(safeId: string): Chain | undefined {
  const parts = safeId.split(":")

  if (parts.length < 3) {
    return undefined
  }

  try {
    const chainId = getChainId({
      namespace: parts[0],
      reference: parseInt(parts[1], 10),
    })
    const chain = getChain(chainId)

    return chain
  } catch (e) {
    return undefined
  }
}

// get all gnosis valid chains
export const getGnosisChains = (): Chain[] => {
  const filteredChains = Object.values(chains).filter(
    (chain) => chain.gnosisServiceURL && chain.gnosisServiceURL.length > 0,
  )

  return filteredChains
}

export const getGnosisChainServiceUrlByChainRef = (
  chainReference: number,
): string | undefined => {
  const gnosisChains = getGnosisChains()

  const foundChain = Object.values(gnosisChains).find(
    (chain) => chainIdToChainReference(chain.id) === chainReference,
  )

  if (foundChain && foundChain.gnosisServiceURL)
    return removeTrailingSlashes(foundChain.gnosisServiceURL)

  return undefined
}

export const isSecurityCheckSupportedInChain = (
  chainReference: number,
): boolean => {
  const chain = getChainByReference(chainReference)

  if (!chain) return false

  return chain.tenderlySupport || chain.hexagateAnalysisSupport
}
