import type { Connector } from "wagmi"

export const ConnectorId = {
  Injected: "injected",
  MetaMask: "metaMask",
  CoinbaseWallet: "coinbaseWallet",
  WalletConnect: "walletConnect",
}

export const hasConnectorSupport = (
  connectors: Connector<any, any, any>[],
  connectorId: string,
): boolean => {
  const existConnector = connectors.find(
    (connector) => connector.id === connectorId,
  )

  return existConnector !== null
}
