import React from "react"
import { createIcon } from "@chakra-ui/icons"

const SlidersUpIcon = createIcon({
  displayName: "SlidersUpIcon",
  viewBox: "0 0 512 512",
  path: (
    <path d="M96 0c13.3 0 24 10.7 24 24V83.7c32.5 10.2 56 40.5 56 76.3s-23.5 66.1-56 76.3V488c0 13.3-10.7 24-24 24s-24-10.7-24-24V236.3C39.5 226.1 16 195.8 16 160s23.5-66.1 56-76.3V24C72 10.7 82.7 0 96 0zm0 192a32 32 0 1 0 0-64 32 32 0 1 0 0 64zM256 384a32 32 0 1 0 0-64 32 32 0 1 0 0 64zm80-32c0 35.8-23.5 66.1-56 76.3V488c0 13.3-10.7 24-24 24s-24-10.7-24-24V428.3c-32.5-10.2-56-40.5-56-76.3s23.5-66.1 56-76.3V24c0-13.3 10.7-24 24-24s24 10.7 24 24V275.7c32.5 10.2 56 40.5 56 76.3zM448 192a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zm-8 76.3V488c0 13.3-10.7 24-24 24s-24-10.7-24-24V268.3c-32.5-10.2-56-40.5-56-76.3s23.5-66.1 56-76.3V24c0-13.3 10.7-24 24-24s24 10.7 24 24v91.7c32.5 10.2 56 40.5 56 76.3s-23.5 66.1-56 76.3z" />
  ),
})

export default SlidersUpIcon
