type Faq = {
  title: string
  content: string
}

// Hardcoded list of Organization FAQs
const ORG_FAQS: Record<string, Record<string, Faq[]>> = {
  "https://api.tally.xyz": {
    // OpenDollar: https://www.tally.xyz/gov/opendollar
    "2235789849409881260": [
      {
        title: "What is Open Dollar?",
        content:
          "Open Dollar is a stablecoin protocol backed by Liquid Staking Tokens and other Arbitrum-native assets with NFT-controlled vaults.",
      },
      {
        title: "What is delegation?",
        content:
          "Only delegated tokens can participate in voting. If you wish to vote on proposals directly, delegate your voting power to your own address. Or, you can delegate your voting power to another community member and let them vote on your behalf.",
      },
      {
        title: "Do I keep my tokens if I delegate?",
        content:
          "Yes, you retain ownership of your tokens even if you delegate them. Delegation only transfers the voting power associated with the tokens, allowing the delegate to vote on your behalf. You can sell or transfer your tokens at any time.",
      },
      {
        title: "Can I change my delegation?",
        content: "Yes, you can change your delegation at any time.",
      },
      {
        title: "Why be a delegate?",
        content:
          "Being a delegate allows you to actively participate in the decision-making process of the DAO by representing the interests and opinions of token holders who have delegated their voting power to you. It gives you the opportunity to have a direct impact on the governance of the DAO.",
      },
      {
        title: "Where can I get ODG?",
        content:
          "You can get ODG on Camelot DEX: [https://info.camelot.exchange/pair/arbitrum/v3/0xf935263c9950eb2881ff58bd6a76c3d2564a78d5](https://info.camelot.exchange/pair/arbitrum/v3/0xf935263c9950eb2881ff58bd6a76c3d2564a78d5)",
      },
      {
        title: "Where can we discuss Governance proposals?",
        content: `You can view, comment and participate on Open Dollar proposals via the link here: [https://forum.opendollar.com/](https://forum.opendollar.com/)

You can also discuss proposals on our community Discord here: [https://discord.com/invite/UbTUfuGBrS](https://discord.com/invite/UbTUfuGBrS)
`,
      },
      {
        title: "How do I submit a governance proposal?",
        content: `Anyone can submit a proposal for discussion on our governance forum, but you must meet the proposal threshold to submit it for voting on the DAO. The current proposal threshold can be referenced under “contract parameters”.

To submit a proposal, simply head on over to the Open Dollar DAO dashboard and click the “Create new proposal” button.

Be sure to read our guidance on the requirements for submitting a proposal via our governance forum here: [https://commonwealth.im/open-dollar-governance/discussion/17069-general-proposal-template](https://commonwealth.im/open-dollar-governance/discussion/17069-general-proposal-template)
        `,
      },
      {
        title: "What kind of proposals can I make?",
        content: `Proposals that benefit the ecosystem of Open Dollar and do not conflict with our minimized-governance.

For more details, reference our DAO Handbook: [https://commonwealth.im/open-dollar-governance/discussion/17054-odg-dao-handbook](https://commonwealth.im/open-dollar-governance/discussion/17054-odg-dao-handbook)
`,
      },
    ],
    // HAI: https://www.tally.xyz/gov/hai
    "2260960194542438166": [
      {
        title: "What is Tally?",
        content:
          "Tally gives users real power in their decentralized organizations. See what's happening. Then, delegate voting power and create or pass proposals to spend DAO funds, manage a protocol or upgrade a smart contract. All onchain. [Learn more](https://docs.tally.xyz/knowledge-base/tally).",
      },
      {
        title: "What is delegation?",
        content:
          "Only delegated tokens can participate in voting. If you wish to vote on proposals directly, delegate your voting power to your own address. Or, you can delegate your voting power to another community member and let them vote on your behalf.",
      },
      {
        title: "Do I keep my tokens if I delegate?",
        content:
          "Yes, you retain ownership of your tokens even if you delegate them. Delegation only transfers the voting power associated with the tokens, allowing the delegate to vote on your behalf. You can sell or transfer your tokens at any time.",
      },

      {
        title: "Can I update my delegation?",
        content: "Yes, you can update your delegation at any time.",
      },
      {
        title: "Why be a delegate?",
        content:
          "Being a delegate allows you to actively participate in the decision-making process of the DAO by representing the interests and opinions of token holders who have delegated their voting power to you. It gives you the opportunity to have a direct impact on the governance of the DAO.",
      },
    ],
  },
  "https://api.staging.tally.xyz": {
    // OpenDollar: https://www.tally.xyz/gov/opendollar
    "2235789849409881260": [
      {
        title: "What is Tally?",
        content:
          "Tally gives users real power in their decentralized organizations. See what's happening. Then, delegate voting power and create or pass proposals to spend DAO funds, manage a protocol or upgrade a smart contract. All onchain. [Learn more](https://docs.tally.xyz/knowledge-base/tally).",
      },
    ],
  },
}

export const getOrganizationFAQs = (organizationId: string) => {
  return (
    ORG_FAQS?.[process.env.NEXT_PUBLIC_TALLY_API_URL]?.[organizationId] ??
    undefined
  )
}
