import type { FC } from "react"
import React from "react"
import { Flex, Stack, Text } from "@chakra-ui/react"
import { isAddress } from "@ethersproject/address"

import BlockExplorerButton from "common/components/BlockExplorerButton"
import CopyButton from "common/components/CopyButton"
import { shortenIfAddress } from "web3/helpers/address"
import { useDevice } from "common/hooks/useDevice"

type ContractAddressProps = {
  label?: string
  address: string
  chainId?: string
}

const ContractAddress: FC<ContractAddressProps> = ({
  label,
  address,
  chainId,
}) => {
  const { onLargeDevice, onLittleDevice } = useDevice()

  return (
    <Flex align="center" justify="space-between" w="full">
      {label && (
        <Flex
          align="center"
          justify="flex-start"
          minW={24}
          textStyle="body.bold.md"
        >
          <Text
            border="1px"
            borderColor="gray.600"
            borderRadius="md"
            fontSize="14px"
            px="2px"
            textTransform="uppercase"
          >
            {label}
          </Text>
        </Flex>
      )}
      <Stack isInline align="center" spacing={1}>
        <Text color="gray.600" display={onLargeDevice} fontSize="14px" mr={3}>
          {address}
        </Text>
        <Text color="gray.600" display={onLittleDevice} fontSize="14px" mr={3}>
          {isAddress(address) ? shortenIfAddress(address) : address}
        </Text>
        {isAddress(address) ? (
          <>
            <CopyButton size={8} tooltipLabel="address" value={address} />
            {chainId ? (
              <BlockExplorerButton
                address={address}
                chainId={chainId}
                size={8}
              />
            ) : null}
          </>
        ) : null}
      </Stack>
    </Flex>
  )
}

export default ContractAddress
