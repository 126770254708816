import {
  Stack,
  Button,
  Text,
  Icon,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  IconButton,
  Skeleton,
} from "@chakra-ui/react"
import React, { useState } from "react"
import { useDebounce } from "use-debounce"

import EmptyEntity from "common/components/EmptyEntity"
import Link from "common/components/Link"
import GovernanceAvatar from "governance/components/GovernanceAvatar"
import Criteria from "common/components/Criteria"
import { ArrowRight } from "ui/components/icons/font-awesome/ArrowRight"
import { ROUTES } from "common/constants/routes"
import { X } from "ui/components/icons/font-awesome/X"
import { useSearchDAOs } from "common/hooks/useSearchDAOs"

const DEBOUNCE_TIME = 500

function SearchDaosDrawer({
  finalRef,
  isOpen,
  onClose,
}: {
  finalRef: React.MutableRefObject<null>
  isOpen: boolean
  onClose: () => void
}) {
  const [criteria, setCriteria] = useState("")
  const [debouncedCriteria] = useDebounce(criteria, DEBOUNCE_TIME)

  const shouldSearch = !!(debouncedCriteria.length > 2)

  const { organizations, isLoading, isFetching } = useSearchDAOs({
    shouldSearch,
    query: debouncedCriteria,
  })

  const shouldDisplaySkeleton = isLoading && isFetching

  function handleClose() {
    setCriteria("")
    onClose()
  }

  return (
    <>
      <Drawer
        finalFocusRef={finalRef}
        isOpen={isOpen}
        placement="right"
        size="full"
        onClose={onClose}
      >
        <DrawerOverlay />
        <DrawerContent height="100% !important!">
          <DrawerHeader p={5}>
            <Stack align="center" direction="row" spacing={3} w="full">
              <Criteria
                border="none"
                criteria={criteria}
                placeholder="Search DAO by name"
                setCriteria={setCriteria}
                w="full"
              />
              <IconButton
                _focusVisible={{
                  boxShadow: "none",
                  borderColor: "transparent",
                  outline: "3px solid hsla(216, 12%, 84%, 1)",
                  outlineOffset: "2px",
                }}
                aria-label="Close menu"
                bg="white"
                data-qa="searchdaosdrawer-closemenu-btn"
                icon={<Icon as={X} fill="neutral.500" h={4} w={4} />}
                size="sm"
                onClick={onClose}
              />
            </Stack>
          </DrawerHeader>
          {criteria.length ? (
            <DrawerBody
              borderTop="1px solid hsla(220, 17%, 93%, 1)"
              overflowY="auto"
              p={5}
              tabIndex={-1}
            >
              {shouldDisplaySkeleton ? (
                <Stack spacing={2}>
                  <Skeleton h="40px" />
                  <Skeleton h="40px" />
                  <Skeleton h="40px" />
                </Stack>
              ) : (
                <>
                  {organizations && organizations.length > 0 ? (
                    <>
                      {organizations.map((org) => {
                        return (
                          <Link
                            key={`search_result_${org.id}`}
                            className="no-underline"
                            href={ROUTES.governance.id(org.slug)}
                            onClick={handleClose}
                          >
                            <Stack
                              _focusVisible={{
                                boxShadow: "none",
                                borderColor: "transparent",
                                outline: "3px solid hsla(216, 12%, 84%, 1)",
                                outlineOffset: "2px",
                              }}
                              _hover={{ bg: "neutral.100" }}
                              align="center"
                              direction="row"
                              p={3}
                              rounded="5px"
                              spacing={3}
                              tabIndex={0}
                            >
                              <GovernanceAvatar
                                size={8}
                                src={org.metadata?.icon}
                              />
                              <Text fontWeight="bold">{org.name}</Text>
                            </Stack>
                          </Link>
                        )
                      })}
                    </>
                  ) : (
                    <EmptyEntity mt={0} title="No DAOs found" />
                  )}
                </>
              )}
            </DrawerBody>
          ) : null}
          <DrawerFooter
            alignItems="center"
            borderTop="1px solid hsla(220, 17%, 93%, 1)"
            justifyContent="center"
            p={5}
          >
            <Link href={ROUTES.explore()} onClick={handleClose}>
              <Button
                _focusVisible={{
                  boxShadow: "none",
                  borderColor: "transparent",
                  outline: "3px solid hsla(216, 12%, 84%, 1)",
                  outlineOffset: "2px",
                }}
                py={1}
                variant="tertiary"
              >
                <Text
                  color="neutral.500"
                  fontSize="14px"
                  fontWeight="medium"
                  lineHeight="18px"
                  mr={1}
                >
                  Explore all DAOs
                </Text>
                <Icon as={ArrowRight} fill="neutral.500" h={4} w={3} />
              </Button>
            </Link>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  )
}

export default SearchDaosDrawer
