import { utils } from "ethers"

export const isSameAddress = (
  aAddress: string | undefined,
  bAddress: string | undefined,
): boolean => {
  return aAddress?.toLocaleLowerCase() === bAddress?.toLocaleLowerCase()
}

// Should put this one in a common helper when needed
function shortenString(str: string) {
  return str.substring(0, 6) + "..." + str.substring(str.length - 4)
}

function shortenAddress(address: string): string {
  try {
    const formattedAddress = utils.getAddress(address)

    return shortenString(formattedAddress)
  } catch {
    return ""
  }
}

// shorten an address if it's valid
export function shortenIfAddress(address: string | undefined): string {
  if (typeof address === "string" && address.length > 0) {
    return shortenAddress(address)
  }

  return ""
}
