import { useAccount } from "wagmi"
import type { ReactElement } from "react"
import { useMemo, useRef, useState, type FC } from "react"
import type { ButtonProps, ComponentWithAs, IconProps } from "@chakra-ui/react"
import type { FetchSignerResult } from "@wagmi/core"
import type { Signer } from "@ethersproject/abstract-signer"
import {
  Button,
  Menu,
  MenuButton,
  MenuItem,
  Text,
  Stack,
  useDisclosure,
  Icon,
  Flex,
  HStack,
  Box,
  Divider,
  MenuList,
  MenuGroup,
  Tooltip,
} from "@chakra-ui/react"
import makeBlockie from "ethereum-blockies-base64"
import { isAddress } from "@ethersproject/address"
import { ChevronRightIcon } from "@chakra-ui/icons"

import { Bolt } from "ui/components/icons/font-awesome/Bolt"
import { RightFromBracket } from "ui/components/icons/font-awesome/RightFromBracket"
import { House } from "ui/components/icons/font-awesome/House"
import { Bell } from "ui/components/icons/font-awesome/Bell"
import WalletSelectionModal from "web3/components/WalletSelectionModal"
import { useConnectAccount } from "web3/components/ConnectAccountButton"
import { useLogout } from "session/hooks/useLogout"
import { useSignerStore } from "web3/providers/SignerProvider"
import type { Me } from "user/providers/MeProvider"
import { useMe } from "user/providers/MeProvider"
import { useSiwe } from "web3/hooks/useSiwe"
import { useConnectorIcon } from "web3/hooks/useConnectorIcon"
import { getDisplayName } from "user/helpers/user"
import UserAvatar from "common/components/UserAvatar"
import { shortString, subString } from "common/helpers/string"
import type { AccountId } from "query/graphql"
import {
  AccountType,
  useAddressSafesQuery,
  type Organization,
} from "query/graphql"
import { ChevronDown } from "ui/components/icons/font-awesome/ChevronDown"
import { useRouter } from "common/hooks/useRouter"
import CopyButton from "common/components/CopyButton"
import Link from "common/components/Link"
import { EXTERNAL_ROUTES, ROUTES } from "common/constants/routes"
import { User } from "ui/components/icons/font-awesome/User"
import { getWhiteLabelRoute } from "whitelabel/utils/breadcrumb"
import { Gear } from "ui/components/icons/font-awesome/Gear"
import { Wallet } from "ui/components/icons/font-awesome/Wallet"
import { useLoginAsSafe } from "session/hooks/useLoginAsSafe"
import Safe from "ui/components/icons/Safe"
import { Spinner } from "ui/components/icons/font-awesome/Spinner"
import Check from "ui/components/icons/Check"
import { shortenIfAddress } from "web3/helpers/address"
import { getAccountAddress } from "web3/helpers/accountId"
import GenericNetworkIcon from "web3/components/icons/GenericChainIcon"
import { findChainBySafeId } from "web3/helpers/chains"
import { useSession } from "session/providers/SessionProvider"

type Props = {
  redirectTo?: {
    login?: string
    logout?: string
    disconnect?: string
  }
  organization?: Organization
  isWhiteLabel: boolean
}
const HeaderAccountMenu: FC<Props> = ({
  redirectTo = {},
  organization,
  isWhiteLabel,
}) => {
  const { address } = useAccount()
  const walletSelectionDisclousure = useDisclosure()

  return (
    <>
      {/* This is hidden since a sign in prompt should be displayed after the user connects wallet. If this ConnectAccountButton is not rendered, then the sign in prompt won't work */}
      <Flex style={{ display: address ? "none" : "block" }}>
        <ConnectAccountButton
          _hover={{
            borderColor: "gray.400",
          }}
          borderColor="gray.200"
          borderRadius="lg"
          h="44px"
          redirectTo={redirectTo?.login}
          variant="primary"
          onOpen={() => walletSelectionDisclousure.onOpen()}
        />
      </Flex>
      <WalletSelectionModal
        isOpen={walletSelectionDisclousure.isOpen}
        onClose={() => walletSelectionDisclousure.onClose()}
      />

      {address ? (
        <HeaderUserMenu
          h="44px"
          isWhiteLabel={isWhiteLabel}
          organization={organization}
          redirectTo={redirectTo?.logout}
          walletAddress={address}
          onOpen={() => walletSelectionDisclousure.onOpen()}
        />
      ) : null}
    </>
  )
}

type ConnectAccountButtonProps = {
  shouldDisplayLabel?: boolean
  onOpen: () => void
  redirectTo?: string
}
const ConnectAccountButton: FC<ConnectAccountButtonProps & ButtonProps> = ({
  shouldDisplayLabel = true,
  onOpen,
  redirectTo = undefined,
  ...buttonProps
}) => {
  const { isLoggedIn } = useSession()
  const { signer } = useSignerStore()
  const { address } = useAccount()
  const connectAccount = useConnectAccount({
    redirectTo,
    onOpen,
    shouldPromptSiwe: true,
  })

  function getLabel({
    address,
    signer,
    isLoggedIn,
    shouldDisplayLabel,
  }: {
    address?: string
    signer?: FetchSignerResult<Signer>
    isLoggedIn: boolean
    shouldDisplayLabel: boolean
  }) {
    if (!shouldDisplayLabel) return null

    if (!address) {
      return "Connect wallet"
    }

    if (address && signer && !isLoggedIn) {
      return "Sign in"
    }

    return "Sign in"
  }

  return (
    <Button
      _focusVisible={{
        boxShadow: "none",
        borderColor: "gray.400",
        outline: "3px solid hsla(216, 12%, 84%, 1)",
        outlineOffset: "2px",
      }}
      data-qa="connect-wallet-button"
      fontWeight="bold"
      variant="primary"
      onClick={(event) => {
        event.stopPropagation()

        connectAccount()
      }}
      {...buttonProps}
    >
      {getLabel({ isLoggedIn, shouldDisplayLabel, address, signer })}
    </Button>
  )
}

type HeaderUserMenuProps = {
  walletAddress: string
  redirectTo?: string
  organization?: Organization
  isWhiteLabel: boolean
  onOpen: () => void
}
const HeaderUserMenu: FC<HeaderUserMenuProps & ButtonProps> = ({
  walletAddress,
  redirectTo,
  organization,
  isWhiteLabel,
  onOpen,
  ...buttonProps
}) => {
  const { logout } = useLogout()
  const { signer } = useSignerStore()
  const me = useMe()
  const { connector } = useAccount()
  const { reload, push } = useRouter()
  const { signInWithEthereum } = useSiwe()

  const connectorIcon = useConnectorIcon()

  const handleLogoutClick = () => {
    logout(redirectTo)
  }

  const handleDisconnectWallet = () => {
    if (!connector) return

    connector.disconnect()
    setTimeout(() => {
      if (redirectTo) {
        push(redirectTo).then(() => reload())
      } else {
        reload()
      }
    }, 1000)
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { address, picture, type, id, displayName } = useMemo(() => {
    if (me) {
      const { address, picture, type, id } = me
      const displayName = getDisplayName(me)

      return { displayName, address, picture, type, id }
    }

    return {
      address: walletAddress ?? "",
      picture: makeBlockie(walletAddress || ""),
      type: "",
      id: "",
      displayName: walletAddress,
    }
  }, [me, walletAddress])

  const copyAddress = me?.type === "SAFE" ? me.address : walletAddress

  return (
    <Menu placement="bottom-end">
      <MenuButton
        _hover={{
          borderColor: "gray.400",
          color: "gray.700",
          cursor: "pointer",
          borderRadius: "lg",
        }}
        as={Button}
        borderWidth="1px"
        data-qa="menu-action-btn"
        variant="secondary"
        {...buttonProps}
      >
        <Stack isInline align="center">
          <UserAvatar address={address} size={6} src={picture} />
          {displayName ? (
            <Text
              data-qa="menu-action-btn-text"
              fontWeight="bold"
              textStyle="sm"
            >
              {isAddress(displayName)
                ? shortString(displayName)
                : subString(displayName, 20)}
            </Text>
          ) : null}
          <Icon as={ChevronDown} h={4} w={4} />
        </Stack>
      </MenuButton>
      <MenuList px={1} py={1} zIndex={900}>
        <Stack align="center" direction="column" pb={2} pt={4} spacing={1}>
          <UserAvatar
            shouldShowBadge
            address={walletAddress}
            badgeIcon={
              <Box
                alignItems="center"
                backgroundColor="white"
                border="1px"
                borderColor="gray.200"
                borderRadius="50%"
                display="inline-flex"
                justifyContent="center"
                p={0.5}
              >
                <Icon as={connectorIcon} h={5} w={5} />
              </Box>
            }
            size="lg"
            src={picture}
          />

          <Stack align="center" direction="row" spacing={1}>
            <Text fontSize="md" fontWeight="medium">
              {shortString(address)}
            </Text>
            <CopyButton size={8} tooltipLabel="address" value={copyAddress} />
          </Stack>

          <Button
            px={4}
            py={1}
            variant="secondary"
            onClick={handleDisconnectWallet}
          >
            Disconnect
          </Button>
        </Stack>

        {organization && address && !isWhiteLabel ? (
          <>
            <Divider my={1} />

            {organization.governorIds.length > 0 ? (
              <MenuLink
                icon={Bolt}
                label="My Voting Power"
                route={ROUTES.governance.myVotingPower.index(organization.slug)}
              />
            ) : null}
            <MenuLink
              icon={User}
              label="My Delegate Profile"
              route={ROUTES.governance.delegate.profile(
                organization.slug,
                address,
              )}
            />
          </>
        ) : null}

        <Divider my={1} />

        {isWhiteLabel && organization ? (
          <MenuLink
            icon={User}
            label="Delegate profile"
            route={getWhiteLabelRoute(
              ROUTES.governance.delegate.profile(organization.slug, address),
            )}
          />
        ) : (
          <>
            <MenuLink
              dataQa="dropdown-main-menu-link-yourDAOs"
              icon={House}
              label="Your DAOs"
              route={ROUTES.user.yourDAOs()}
            />
            {address ? (
              <MenuLink
                dataQa="dropdown-main-menu-link-profile"
                icon={User}
                label="Profile"
                route={ROUTES.profile(address)}
              />
            ) : null}
          </>
        )}

        {!isWhiteLabel ? (
          <MenuLink
            isExternal
            dataQa="dropdown-main-menu-link-notifications"
            icon={Bell}
            label="Notifications"
            route={EXTERNAL_ROUTES.notifications.index(id)}
          />
        ) : null}

        <MenuLink
          dataQa="dropdown-main-menu-link-settings"
          icon={Gear}
          label="Settings"
          route={ROUTES.user.settings()}
        />

        {type === AccountType.Safe && walletAddress && signer ? (
          <MenuItem
            h={10}
            onClick={() =>
              signInWithEthereum({ address: walletAddress, signer })
            }
          >
            <Stack isInline align="center" pl={1} spacing={2} w="full">
              <Icon as={Wallet} color="gray.600" h={4} w={4} />
              <Stack align="center" direction="row" spacing={2}>
                <Text
                  color="gray.900"
                  data-qa="dropdown-main-menu-safe-switchto-text"
                  fontSize="sm"
                  fontWeight="medium"
                >
                  Switch to
                </Text>
                <Stack align="center" direction="row" rounded="md">
                  <Icon as={connectorIcon} color="gray.600" h={5} w={5} />
                  <Text color="gray.900" fontSize="sm" fontWeight="medium">
                    {shortString(walletAddress)}
                  </Text>
                </Stack>
              </Stack>
            </Stack>
          </MenuItem>
        ) : (
          <SafesMenu me={me} />
        )}

        {!me ? (
          <>
            <Divider mt={1} />
            <ConnectAccountButton
              mt={2}
              variant="primary"
              width="100%"
              onOpen={onOpen}
            />
          </>
        ) : null}

        {me ? (
          <>
            <Divider my={1} />
            <MenuLink
              isExternal
              dataQa="dropdown-main-menu-logout"
              icon={RightFromBracket}
              label="Logout"
              onClick={handleLogoutClick}
            />
          </>
        ) : null}
      </MenuList>
    </Menu>
  )
}

type MenuLinkProps = {
  route?: string
  icon: ComponentWithAs<"svg", IconProps>
  label: string | ReactElement
  id?: string
  onClick?: () => void
  isExternal?: boolean
  rightIcon?: ComponentWithAs<"svg", IconProps>
  dataQa?: string
}
const MenuLink: FC<MenuLinkProps> = ({
  icon,
  label,
  isExternal,
  onClick,
  route,
  rightIcon,
  dataQa,
}) => {
  return (
    <>
      {route != null ? (
        <MenuItem
          key={route}
          as={Link}
          className="no-underline"
          data-qa={dataQa}
          href={route}
          isExternal={isExternal}
          mx={0}
          px={0}
          py={0}
        >
          <HStack
            _hover={{ bgColor: "gray.100", color: "black" }}
            align="center"
            bgColor="auto"
            borderRadius="lg"
            color="gray.900"
            fontSize="sm"
            fontWeight="medium"
            px={4}
            py={2}
            width="100%"
          >
            {icon ? (
              <Box _hover={{ color: "gray.900" }} color="gray.600">
                <Icon as={icon} h={4} w={4} />
              </Box>
            ) : null}
            <Text pt={1}>{label}</Text>
            {rightIcon ? (
              <Box _hover={{ color: "gray.900" }} color="gray.600">
                <Icon as={rightIcon} h={4} w={4} />
              </Box>
            ) : null}
          </HStack>
        </MenuItem>
      ) : (
        <MenuItem
          borderRadius="lg"
          data-qa={dataQa}
          mx={0}
          px={0}
          py={0}
          onClick={onClick}
        >
          <HStack
            _hover={{ bgColor: "gray.100", color: "black" }}
            align="center"
            bgColor="auto"
            borderRadius="lg"
            color="gray.900"
            fontSize="sm"
            fontWeight="medium"
            px={4}
            py={2}
            width="100%"
          >
            {icon ? (
              <Box _hover={{ color: "gray.900" }} color="gray.500">
                <Icon as={icon} h={4} w={4} />
              </Box>
            ) : null}
            <Text pt={1}>{label}</Text>
            {rightIcon ? (
              <Box _hover={{ color: "gray.900" }} color="gray.500">
                <Icon as={rightIcon} h={4} w={4} />
              </Box>
            ) : null}
          </HStack>
        </MenuItem>
      )}
    </>
  )
}

const SafesMenu = ({ me }: { me?: Me }) => {
  const [isOpen, setIsOpen] = useState(false)

  const menuListRef = useRef<HTMLDivElement>(null)

  const { data, isLoading, isFetched } = useAddressSafesQuery(
    { accountId: me?.id ?? "" },
    { enabled: isOpen && Boolean(me) },
  )
  const { loginAsSafe } = useLoginAsSafe()

  const connectAccount = useConnectAccount({
    shouldPromptSiwe: true,
  })

  // If user didn't sign in, then display a button to sign in as usual
  if (!me) {
    return (
      <MenuItem
        borderRadius="lg"
        mx={0}
        px={0}
        py={0}
        onClick={(event) => {
          event.stopPropagation()

          connectAccount()
        }}
      >
        <HStack
          _hover={{ bgColor: "gray.100", color: "black" }}
          align="center"
          bgColor="auto"
          borderRadius="lg"
          color="gray.900"
          fontSize="sm"
          fontWeight="medium"
          px={4}
          py={2}
          width="100%"
        >
          <Box _hover={{ color: "gray.900" }} color="gray.500">
            <Icon as={Safe} h={4} w={4} />
          </Box>
          <Text pt={1}>Sign in as Safe</Text>
        </HStack>
      </MenuItem>
    )
  }

  const { address, picture } = me
  const displayName = getDisplayName(me)
  const handleLoginAsSafe = (safe: AccountId) => {
    loginAsSafe(safe)
  }

  const safeIds = data?.account.safes ?? []

  function isMenuOpen() {
    if (typeof window === "undefined") return

    const style = menuListRef.current
      ? window.getComputedStyle(menuListRef.current)
      : undefined
    setIsOpen(style?.visibility === "hidden")
  }

  return (
    <Menu placement="left-start">
      <MenuItem
        _hover={{
          bg: "gray.100",
          borderRadius: "lg",
        }}
        as={MenuButton}
        className="chakra-menu__menuitem css-18esm8n"
        data-qa="dropdown-main-menu-btn-signinassafe"
        h={10}
        onClick={isMenuOpen}
      >
        <Stack
          isInline
          align="center"
          justify="space-between"
          spacing={2}
          w="full"
        >
          <HStack>
            <Icon as={Safe} h={5} w={5} />
            <Text
              flex="unset"
              fontSize="sm"
              fontWeight="medium"
              textAlign="start"
            >
              Sign in as Safe
            </Text>
          </HStack>
          <Icon as={ChevronRightIcon} color="gray.500" h={6} w={6} />
        </Stack>
      </MenuItem>
      <MenuList ref={menuListRef} p={1}>
        <MenuGroup mx={3} title="EOA">
          <MenuItem h={10}>
            <Stack isInline align="center" justify="space-between" w="full">
              <Stack isInline align="center">
                <UserAvatar address={address} size={6} src={picture} />
                {displayName ? (
                  <Text fontWeight="bold" textStyle="sm">
                    {isAddress(displayName)
                      ? shortString(displayName)
                      : displayName}
                  </Text>
                ) : null}
              </Stack>
              <Icon as={Check} h={4} w={4} />
            </Stack>
          </MenuItem>
        </MenuGroup>
        <MenuGroup mx={3} title="Safes">
          {isLoading ? (
            <MenuItem h={10}>
              <Stack
                isInline
                align="center"
                justify="center"
                spacing={3}
                w="full"
              >
                <Icon as={Spinner} className="rotate" fill="gray.400" />
                <Text color="gray.400" textStyle="body.bold.sm">
                  Loading safes
                </Text>
              </Stack>
            </MenuItem>
          ) : null}
          {isFetched && safeIds.length > 0 ? (
            <Flex direction="column" maxH="400px" overflowY="auto">
              {safeIds?.map((safeId, index) => {
                const safeAddress = getAccountAddress(safeId)

                const chain = findChainBySafeId(safeId)
                const networkIcon = chain
                  ? GenericNetworkIcon(chain)
                  : undefined

                const tooltipLabel = `${safeAddress} is on ${
                  !!chain ? chain.mediumName : "an unsupported network"
                }`

                return (
                  <MenuItem
                    key={`safe-${index}`}
                    h={10}
                    onClick={() => handleLoginAsSafe(safeId)}
                  >
                    <Tooltip hasArrow label={tooltipLabel} placement="right">
                      <Stack
                        isInline
                        align="center"
                        justify="left"
                        spacing={3}
                        w="full"
                      >
                        {!!networkIcon ? (
                          <Icon as={networkIcon} h={5} w={5} />
                        ) : (
                          <Icon as={Safe} h={5} w={5} />
                        )}
                        <Text color="gray.600" textStyle="body.bold.md">
                          {shortenIfAddress(safeAddress)}
                        </Text>
                      </Stack>
                    </Tooltip>
                  </MenuItem>
                )
              })}
            </Flex>
          ) : null}
          {isFetched && safeIds.length === 0 ? (
            <Flex align="center" justify="center" mx={3} my={1.5} w="full">
              <Text color="gray.600" textStyle="body.bold.md">
                You don&apos;t own a safe
              </Text>
            </Flex>
          ) : null}
        </MenuGroup>
      </MenuList>
    </Menu>
  )
}

export default HeaderAccountMenu
