export const DATE_FORMATS = {
  monthDayYear: "MMM D, YYYY",
  dayMonthYear: "D MMM YYYY",
  fullDayMinMonthFullYear: "DD MMM YYYY",
  statusHistory: "MMM D - hh:mm a",
  statusHistoryFullMonth: "MMMM D, hh:mm a",
  statusHistoryFullYear: "MMMM Do, YYYY - hh:mm a",
  year: "YYYY",
  monthOrdinalDay: "MMM Do hh:mm a",
  numericDayMonthYear: "DD/MM/YYYY",
  monthThYear: "MMM Do, YYYY",
  timeline: "ddd MMM D, hh:mm a",
  monthYear: "MMM YYYY",
  timelineWithTimezone: "MMM D, hh:mm a [GMT]Z",
}
