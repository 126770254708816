import React from "react"
import { createIcon } from "@chakra-ui/icons"

const Safe = createIcon({
  displayName: "Safe",
  viewBox: "0 0 661.6 661.5",
  path: (
    <>
      <path
        d="M532,330.7h-49.4c-14.8,0-26.7,12-26.7,26.7v71.7c0,14.8-12,26.7-26.7,26.7H232.5c-14.8,0-26.7,12-26.7,26.7V532
	c0,14.8,12,26.7,26.7,26.7h208c14.8,0,26.5-12,26.5-26.7v-39.6c0-14.8,12-25.2,26.7-25.2H532c14.8,0,26.7-12,26.7-26.7v-83.3
	C558.7,342.3,546.7,330.7,532,330.7L532,330.7z"
      />
      <path
        d="M205.8,232.5c0-14.8,12-26.7,26.7-26.7H429c14.8,0,26.7-12,26.7-26.7v-49.4c0-14.8-12-26.7-26.7-26.7H221.1
	c-14.8,0-26.7,12-26.7,26.7v38.1c0,14.8-12,26.7-26.7,26.7h-38c-14.8,0-26.7,12-26.7,26.7v83.4c0,14.8,12,26.1,26.8,26.1h49.4
	c14.8,0,26.7-12,26.7-26.7L205.8,232.5L205.8,232.5z"
      />
      <path
        d="M307.5,278.8H355c15.5,0,28,12.6,28,28v47.5c0,15.5-12.6,28-28,28h-47.5c-15.5,0-28-12.6-28-28v-47.5
	C279.5,291.3,292.1,278.8,307.5,278.8z"
      />
    </>
  ),
})

export default Safe
