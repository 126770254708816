import React from "react"
import { createIcon } from "@chakra-ui/icons"

const MetaMaskIcon = createIcon({
  displayName: "MetaMaskIcon",
  viewBox: "0 0 40 40",
  path: (
    <g>
      <path
        d="M34.413 4.457l-12.492 9.278 2.31-5.474 10.182-3.804z"
        fill="#E2761B"
        stroke="#E2761B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.574 4.457l12.392 9.366-2.197-5.562L5.574 4.457zM29.918 25.964l-3.327 5.097 7.119 1.959 2.046-6.943-5.838-.113zM4.256 26.077l2.034 6.942 7.118-1.958-3.327-5.097-5.825.113z"
        fill="#E4761B"
        stroke="#E4761B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.007 17.35l-1.984 3.001 7.068.314-.25-7.595-4.834 4.28zM26.98 17.351l-4.896-4.37-.163 7.685 7.056-.314-1.997-3.001zM13.408 31.06l4.244-2.07-3.666-2.863-.578 4.934zM22.335 28.99l4.256 2.07-.59-4.933-3.666 2.862z"
        fill="#E4761B"
        stroke="#E4761B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.591 31.06l-4.256-2.07.339 2.774-.038 1.168 3.955-1.871zM13.409 31.06l3.954 1.872-.025-1.168.314-2.775-4.243 2.072z"
        fill="#D7C1B3"
        stroke="#D7C1B3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.426 24.294l-3.54-1.042 2.498-1.143 1.042 2.185zM22.561 24.294l1.042-2.185 2.511 1.143-3.553 1.042z"
        fill="#233447"
        stroke="#233447"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.41 31.06l.602-5.096-3.93.113 3.327 4.984zM25.99 25.964l.602 5.097 3.327-4.984-3.93-.113zM28.978 20.352l-7.056.313.653 3.629 1.042-2.185 2.51 1.143 2.85-2.9zM13.886 23.252l2.511-1.143 1.03 2.185.665-3.629-7.068-.313 2.862 2.9z"
        fill="#CD6116"
        stroke="#CD6116"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.023 20.352l2.963 5.775-.1-2.875-2.863-2.9zM26.127 23.252l-.125 2.875 2.975-5.775-2.85 2.9zM18.092 20.666l-.665 3.628.828 4.281.189-5.637-.352-2.272zM21.921 20.666l-.339 2.26.15 5.65.842-4.282-.653-3.628z"
        fill="#E4751F"
        stroke="#E4751F"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.574 24.294l-.842 4.281.603.414 3.666-2.862.126-2.875-3.553 1.042zM13.886 23.252l.1 2.875 3.666 2.862.603-.414-.829-4.281-3.54-1.042z"
        fill="#F6851B"
        stroke="#F6851B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.637 32.932l.037-1.168-.314-.276h-4.733l-.289.276.025 1.168-3.954-1.871 1.38 1.13 2.8 1.946h4.809l2.812-1.946 1.381-1.13-3.954 1.87z"
        fill="#C0AD9E"
        stroke="#C0AD9E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.335 28.99l-.602-.415h-3.478l-.603.414-.314 2.775.29-.276h4.732l.314.276-.339-2.775z"
        fill="#161616"
        stroke="#161616"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34.94 14.338l1.067-5.123-1.594-4.758-12.078 8.964 4.645 3.93 6.567 1.92 1.456-1.694-.628-.452 1.005-.917-.779-.602 1.005-.766-.666-.502zM3.992 9.215l1.067 5.123-.678.502 1.005.766-.766.602 1.004.917-.627.452 1.443 1.695 6.567-1.921 4.645-3.93L5.574 4.457 3.992 9.215z"
        fill="#763D16"
        stroke="#763D16"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33.547 19.272L26.98 17.35l1.997 3L26 26.128l3.917-.05h5.838l-2.21-6.805zM13.007 17.35L6.44 19.273l-2.184 6.805h5.825l3.905.05-2.963-5.775 1.984-3.001zM21.921 20.666l.414-7.245 1.909-5.16h-8.475l1.883 5.16.44 7.245.15 2.285.013 5.624h3.478l.025-5.624.163-2.285z"
        fill="#F6851B"
        stroke="#F6851B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  ),
})

export default MetaMaskIcon
