import type { FC, ReactNode } from "react"
import React from "react"
import Head from "next/head"

import type { Preview } from "common/types/header"

type Props = {
  title: string
  preview?: Preview
  customHead?: ReactNode
}

const MainLayoutHead: FC<Props> = ({ title, preview, customHead }) => {
  return (
    <Head>
      <title>{title}</title>
      <meta content="Tally" property="og:site_name" />
      <meta content="website" property="og:type" />
      {preview && (
        <>
          {preview.image ? (
            <>
              <meta content={preview.image} property="og:image" />
              <meta content={preview.image} name="twitter:image" />
            </>
          ) : null}
          <meta content="@tallyxyz" name="twitter:site" />
          <meta content={preview.title} property="og:title" />
          {preview.description ? (
            <meta content={preview.description} property="og:description" />
          ) : null}
        </>
      )}
      {customHead ? <>{customHead}</> : null}
    </Head>
  )
}

export default MainLayoutHead
