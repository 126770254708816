import { AssetType, ChainIdParams, AssetTypeParams } from "caip"
import { TokenType } from "query/graphql"
import isEqual from "lodash.isequal"
import { AssetNameParams } from "caip/dist/assetName"

const getAssetNameParams = (assetName: string) => {
  const [namespace, reference] = assetName.split(":")

  return { namespace, reference }
}


export const getAssetId = ({ assetName, chainId }: AssetTypeParams): string => {
  const composeAssetId = (namespace: string, reference: string, chainId: string | ChainIdParams) => {
    const lower = (string: string) => string.toLocaleLowerCase()

    return AssetType.format({ assetName: { namespace: lower(namespace), reference }, chainId })
  }

  if (typeof assetName === "string") {
    const { namespace, reference } = getAssetNameParams(assetName)

    return composeAssetId(namespace, reference, chainId)
  }

  const { namespace, reference } = assetName

  return composeAssetId(namespace, reference, chainId)
}

export const getTokenTypeFromString = (type: string): TokenType => {

  const keyFound = Object.keys(TokenType).find((key) => isEqual(key.toLowerCase(), type.toLowerCase()))

  return keyFound
    ? TokenType[keyFound as keyof typeof TokenType]
    : TokenType.Erc20
}

type AssetParams = {
  address: string
  chainId: string
}

export const getAssetIdParams = (assetId: string): { address: string, chainId: string, tokenType: TokenType } => {
  const { assetName, chainId } = AssetType.parse(assetId)

  const getChainId = (chainId: ChainIdParams | string): string => {
    if (typeof chainId === "string") return chainId

    return `${chainId.namespace}:${chainId.reference}`
  }

  return {
    address: (assetName as AssetNameParams).reference,
    chainId: getChainId(chainId as ChainIdParams),
    tokenType: getTokenTypeFromString((assetName as AssetNameParams).namespace)
  }
}

