import type { FC, ReactNode } from "react"
import type { TagProps } from "@chakra-ui/react"
import { Tag, TagLabel } from "@chakra-ui/react"

type Props = {
  children: ReactNode
}
const CommonTag: FC<Props & TagProps> = ({ children, ...tagProps }) => {
  return (
    <Tag
      bgColor="purple.50"
      border="purple.100"
      max="true"
      rounded="full"
      size="md"
      variant="subtle"
      {...tagProps}
    >
      <TagLabel color="purple.600" py={1} textTransform="none" {...tagProps}>
        {children}
      </TagLabel>
    </Tag>
  )
}

export default CommonTag
