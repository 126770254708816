import type { FC } from "react"
import { useEffect } from "react"
import { Button, Stack, useToast } from "@chakra-ui/react"
import { useFormik } from "formik"

import {
  OrganizationRole,
  useOrganizationAddAdminFormQuery,
  useUpdateOrganizationAdminsMutation,
} from "query/graphql"
import type { Member } from "query/graphql"
import FormSelect from "ui/components/form/FormSelect"
import FormInput from "ui/components/form/FormInput"
import { addAdminInOrganizationValidationSchema } from "organization/validations/organization"
import { isSameAddress } from "web3/helpers/address"
import { getOrganizationRoleLabel } from "organization/helpers/role"

type AddAdminForm = {
  address: string
  role: OrganizationRole
}

type Props = {
  address?: string
  role?: OrganizationRole
  organizationId: string
  onSave: () => void
  onCancel: () => void
}
const OrganizationAddAdminForm: FC<Props> = ({
  address = "",
  role = OrganizationRole.Admin,
  organizationId,
  onSave,
  onCancel,
}) => {
  const toast = useToast()

  const isAddressReadOnly = Boolean(address)

  const INITIAL_VALUES: AddAdminForm = {
    address,
    role,
  }

  const { data } = useOrganizationAddAdminFormQuery({
    input: {
      filters: {
        organizationId,
        roles: [OrganizationRole.Superadmin, OrganizationRole.Admin],
      },
    },
  })

  const admins =
    data && data.organizationMembers ? data.organizationMembers.nodes : []

  const {
    mutate: updateOrganizationAdminsMutate,
    isError,
    isSuccess,
    isLoading,
  } = useUpdateOrganizationAdminsMutation()

  const onSubmit = (values: AddAdminForm) => {
    try {
      const memberFound = admins.find((admin) =>
        isSameAddress((admin as Member).account.address, values.address),
      )

      if (!memberFound || isAddressReadOnly) {
        updateOrganizationAdminsMutate({
          input: {
            id: organizationId,
            add: [{ address: values.address, role: values.role }],
          },
        })
      } else {
        toast({
          status: "error",
          description:
            "This address is already a Tally metadata admin of the DAO",
        })
      }
    } catch (error) {
      const errorMessage = (error as any)?.response?.errors[0]?.extensions
        ?.status?.message
      const errorDefaultMessage = `Sorry, we couldn't add this Tally metadata admin to your DAO`

      toast({
        status: "error",
        description: errorMessage ?? errorDefaultMessage,
      })
    }
  }

  useEffect(() => {
    if (isSuccess) {
      onSave()
    }

    if (isError) {
      toast({
        title: "Error",
        status: "error",
        description: "There is an error adding this address. Please, try again",
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, isError])

  const { handleSubmit, errors, touched, values, handleChange, handleBlur } =
    useFormik<AddAdminForm>({
      onSubmit,
      initialValues: INITIAL_VALUES,
      validationSchema: addAdminInOrganizationValidationSchema,
      validateOnChange: false,
      enableReinitialize: true,
      validateOnBlur: false,
    })

  return (
    <form onSubmit={handleSubmit}>
      <Stack maxW="2xl" mb={2} spacing={5} w="full">
        <FormInput
          errors={errors}
          extraLabel="This address should be a member of the DAO"
          isReadOnly={isLoading || isAddressReadOnly}
          label="Address"
          name="address"
          placeholder="What is the user address?"
          touched={touched}
          values={values}
          onBlur={handleBlur}
          onChange={handleChange}
        />

        <FormSelect
          errors={errors}
          extraLabel={`A ${getOrganizationRoleLabel(
            OrganizationRole.Superadmin,
          )} role can add or remove other users`}
          isReadOnly={isLoading}
          label="Role"
          name="role"
          placeholder=""
          touched={touched}
          value={values.role}
          onBlur={handleBlur}
          onChange={handleChange}
        >
          <option value={OrganizationRole.Admin}>
            {getOrganizationRoleLabel(OrganizationRole.Admin)}
          </option>
          <option value={OrganizationRole.Superadmin}>
            {getOrganizationRoleLabel(OrganizationRole.Superadmin)}
          </option>
        </FormSelect>
        <Stack direction="row" pt={6} spacing={2}>
          <Button
            isLoading={isLoading}
            size="md"
            type="submit"
            variant="primary"
          >
            Save changes
          </Button>
          <Button size="md" variant="terniary" onClick={onCancel}>
            Cancel
          </Button>
        </Stack>
      </Stack>
    </form>
  )
}

export default OrganizationAddAdminForm
