import type { Dayjs, QUnitType } from "dayjs"
import dayjs, { extend } from "dayjs"
import relativeTime from "dayjs/plugin/relativeTime"
import duration from "dayjs/plugin/duration"
import * as utc from "dayjs/plugin/utc"
import timezone from "dayjs/plugin/timezone"
import * as advancedFormat from "dayjs/plugin/advancedFormat"

import { pluralize } from "common/helpers/string"
import { DATE_FORMATS } from "common/constants/date"

extend(advancedFormat.default)
extend(utc.default)
extend(timezone)
extend(relativeTime)

export const getDateFromTimestamp = (timestamp: number): Dayjs => {
  return dayjs(timestamp)
}

export const getDateFromBlocks = (
  blocks: number,
  blockTime: number,
): string => {
  return dayjs().to(dayjs().add(blocks * blockTime, "seconds"), true)
}

export const getDatePlusBlocks = (
  timestamp: string,
  blocks: number,
  blockTime: number,
): string => {
  return dayjs(timestamp)
    .add(blocks * blockTime, "seconds")
    .format("YYYY-MM-DD HH:mm:ss")
}

export const getDateFromSeconds = (seconds: number): string => {
  return dayjs().to(dayjs().add(seconds, "seconds"), true)
}

export const getTimestampFromIetf = (ietfTimestamp: string): number => {
  return Date.parse(ietfTimestamp)
}

export const getDateFromNow = (
  timestamp: string,
  withoutSuffix = true,
  detailed = false,
) => {
  const now = dayjs()
  const targetTime = dayjs(timestamp)
  const diffInMilliseconds = targetTime.diff(now)

  if (detailed) {
    const diffInHours = diffInMilliseconds / (1000 * 60 * 60)

    if (Math.abs(diffInHours) < 24) {
      const hours = Math.floor(Math.abs(diffInHours))
      const minutes = Math.floor(
        (Math.abs(diffInMilliseconds) % (1000 * 60 * 60)) / (1000 * 60),
      )

      const hoursText = `${hours} ${pluralize(hours, "hour", "hours")}`
      const minutesText =
        minutes > 0
          ? ` and ${minutes} ${pluralize(minutes, "minute", "minutes")}`
          : ""

      return `${hoursText}${minutesText}`
    }
  }

  return targetTime.fromNow(withoutSuffix)
}

export const getTimestampFormat = (
  timestamp: string,
  format: string = DATE_FORMATS.dayMonthYear,
  isUtc = false,
) => {
  if (isUtc) {
    return dayjs(timestamp).utc().format(format)
  }

  return dayjs(timestamp).format(format)
}

export const getDifferenceTimeFromNow = (
  timestamp: string,
  unit: QUnitType = "day",
): number => {
  return dayjs().diff(timestamp, unit)
}

export const isAttemptExpired = (
  timestamp: string,
  expireTimeMins = 30,
): boolean => {
  const attemptTimestamp = dayjs(timestamp).add(expireTimeMins, "minute")

  const now = dayjs()

  return attemptTimestamp.isBefore(now)
}

export function formatCountdown(timestamp: string) {
  dayjs.extend(duration)
  const now = dayjs()
  const end = dayjs(timestamp)

  const diff = end.diff(now)
  const dur = dayjs.duration(diff)

  const days = dur.days()
  const hours = dur.hours()
  const minutes = dur.minutes()

  const countdown = []

  if (days > 0) {
    countdown.push(pluralize(days, "1 day", `${days} days`))
    countdown.push(pluralize(hours, "1 hour", `${hours} hours`))
  } else {
    if (hours > 0) {
      countdown.push(pluralize(hours, "1 hour", `${hours} hours`))
    }

    if (minutes > 0) {
      countdown.push(pluralize(minutes, "1 minute", `${minutes} minutes`))
    }
  }

  return countdown.length > 1
    ? countdown.slice(0, -1).join(", ") + " and " + countdown.slice(-1)
    : countdown.join("")
}
