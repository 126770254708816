import { setCookie } from "nookies"
import { useQueryClient } from "@tanstack/react-query"

import type { LoginMutation, SignInType } from "query/graphql"
import { LoginDocument } from "query/graphql"
import { fetcher, GrpcErrorType } from "common/helpers/fetcher"
import { useSession } from "session/providers/SessionProvider"
import { useRouter } from "common/hooks/useRouter"
import { useRefetchMe } from "user/providers/MeProvider"

export const useLogin = () => {
  const { setIsLoggedIn } = useSession()
  const { push } = useRouter()
  const refetch = useRefetchMe()
  const queryClient = useQueryClient()

  const login = (
    message: string,
    signature: string,
    nonceToken: string,
    signInType: SignInType,
    redirectTo?: string,
  ) => {
    fetcher
      .gql<LoginMutation>({
        query: LoginDocument,
        omittedErrors: [
          GrpcErrorType.InvalidArgument,
          GrpcErrorType.PermissionDenied,
        ],
        variables: {
          message,
          signature,
          signInType,
        },
        optionalHeaders: {
          nonce: nonceToken,
        },
      })
      .then((data) => {
        if (!data) return

        const { login: token } = data
        setCookie(null, "token", token, { path: "/" })
        setIsLoggedIn(true)

        if (redirectTo) push(redirectTo)

        refetch()

        const queriesToInvalidate = ["UserOrganizations", "UserGovernances"]
        queriesToInvalidate.forEach((query) =>
          queryClient.invalidateQueries([query]),
        )
      })
  }

  return { login }
}
