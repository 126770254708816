import type { FC, ReactNode } from "react"
import React from "react"
import type { FlexProps } from "@chakra-ui/react"
import { Flex, Image, Tooltip, Icon } from "@chakra-ui/react"
import makeBlockie from "ethereum-blockies-base64"

import TwitterIcon from "ui/components/icons/TwitterIcon"
import { useMe } from "user/providers/MeProvider"
import { AccountType } from "query/graphql"
import Safe from "ui/components/icons/Safe"

export type AvatarSize = "xs" | "sm" | "md" | "lg" | "xl" | "xxl" | number

type Props = {
  src?: string | null
  size?: AvatarSize
  // DO NOT make "address" optinal, this is a required prop. Breaks many required flows to work incorrectly
  address: string
  shouldShowBadge?: boolean
  isVerifiedByTwitter?: boolean
  badgeIcon?: ReactNode
}

const UserAvatar: FC<Props & FlexProps> = ({
  src,
  size = "md",
  // DO NOT make "address" optinal, this is a required prop. Breaks many required flows to work incorrectly
  address,
  badgeIcon,
  shouldShowBadge = true,
  isVerifiedByTwitter = false,
  ...flexProps
}) => {
  const me = useMe()
  const hasImage = Boolean(src)
  const srcToUse = hasImage && typeof src === "string" ? src : undefined
  const sizes = {
    xs: 4,
    sm: 8,
    md: 12,
    lg: 16,
    xl: 20,
    xxl: 32,
  }
  const iconSize = typeof size === "number" ? size : sizes[size]

  const calculateIconSize = (size: number): string => `${(size * 4) / 32}em`

  const verificationBadgeSize = calculateIconSize(iconSize)

  // const calculateAvatarSize = (size: number): string => `${size * 8}`
  // const stamp = `https://cdn.stamp.fyi/avatar/${address}?s=${calculateAvatarSize(
  //   iconSize,
  // )}`

  return (
    <Flex
      align="center"
      bg="white"
      h={iconSize}
      justify="center"
      minW={iconSize}
      pos="relative"
      rounded="full"
      w={iconSize}
      {...flexProps}
    >
      <Image
        alt="User profile image"
        h="100%"
        objectFit="cover"
        objectPosition="center-right"
        rounded="full"
        src={hasImage ? srcToUse : makeBlockie(address)}
        w={iconSize}
      />
      {isVerifiedByTwitter && shouldShowBadge ? (
        <Tooltip
          hasArrow
          aria-label="Verified by Twitter"
          label="Address linked on Twitter"
          placement="top-end"
        >
          <Flex
            align="center"
            justify="center"
            pos="absolute"
            right="-px"
            top="-px"
            zIndex={3}
          >
            <Icon
              as={TwitterIcon}
              bg="white"
              border="white.1"
              fill="external.twitter"
              h={verificationBadgeSize}
              padding="2px"
              rounded="full"
              w={verificationBadgeSize}
            />
          </Flex>
        </Tooltip>
      ) : null}

      {badgeIcon && shouldShowBadge ? (
        <Flex
          align="center"
          justify="center"
          pos="absolute"
          right="-5px"
          top="-4px"
          zIndex={3}
        >
          {badgeIcon}
        </Flex>
      ) : null}

      {address === me?.address && me?.type === AccountType.Safe ? (
        <Tooltip
          hasArrow
          aria-label="Is a safe account"
          label="Is a safe account"
          placement="top-end"
        >
          <Flex
            align="center"
            justify="center"
            pos="absolute"
            right="-px"
            top="-4px"
            zIndex={3}
          >
            <Icon
              as={Safe}
              bg="#12ff80"
              fill="black"
              h={verificationBadgeSize}
              rounded="full"
              w={verificationBadgeSize}
            />
          </Flex>
        </Tooltip>
      ) : null}
    </Flex>
  )
}

export default UserAvatar
