import type { FC } from "react"
import React, { useEffect, useState } from "react"
import {
  Stack,
  Flex,
  Text,
  Box,
  Button,
  useDisclosure,
  Skeleton,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  useToast,
  AlertDialogCloseButton,
} from "@chakra-ui/react"
import isEqual from "lodash.isequal"

import { useOrganization } from "organization/providers/OrganizationProvider"
import type { Member } from "query/graphql"
import {
  OrganizationRole,
  useOrganizationAdminListQuery,
  useRemoveSuperAdminMutation,
  useUpdateOrganizationAdminsMutation,
} from "query/graphql"
import { isSameAddress } from "web3/helpers/address"
import OrganizationAdmin from "organization/components/OrganizationAdmin"
import OrganizationAddAdminForm from "organization/components/OrganizationAddAdminForm"
import { generateArrayOfNumbers } from "common/helpers/array"
import CardContainer from "common/components/CardContainer"
import { useMe } from "user/providers/MeProvider"
import { getOrganizationRoleLabel } from "organization/helpers/role"

type Props = {
  organizationId: string
  isReadOnly?: boolean
}
const OrganizationAdminList: FC<Props> = ({
  organizationId,
  isReadOnly = false,
}) => {
  const [address, setAddress] = useState<string>("")
  const [role, setRole] = useState<OrganizationRole | undefined>()
  const { myRole, refetch: refetchContext } = useOrganization()
  const toast = useToast()

  const { isOpen, onOpen, onClose } = useDisclosure()

  const { data, isLoading, refetch } = useOrganizationAdminListQuery({
    input: {
      filters: {
        organizationId,
        roles: [OrganizationRole.Superadmin, OrganizationRole.Admin],
      },
    },
  })

  const {
    mutate: updateOrganizationAdminsMutate,
    isError: updateOrganizationAdminsIsError,
    isSuccess: updateOrganizationAdminsIsSuccess,
  } = useUpdateOrganizationAdminsMutation()

  const {
    mutate: removeSuperAdminMutate,
    isError: removeSuperAdminisError,
    isSuccess: removeSuperAdminisSuccess,
  } = useRemoveSuperAdminMutation()

  // const organization = data && data.organizationV2
  const organization = data && data.organizationMembers
  const admins = organization && (organization.nodes as Member[])

  const hasAdmins = admins && admins?.length > 0

  const hasSuperAdminRole = myRole === OrganizationRole.Superadmin

  useEffect(() => {
    if (updateOrganizationAdminsIsSuccess) {
      refetch()
      refetchContext()
    }

    if (updateOrganizationAdminsIsError) {
      toast({
        title: "Error",
        status: "error",
        description: "There is an error removing this admin. Please, try again",
      })
    }

    if (removeSuperAdminisSuccess) {
      refetch()
      refetchContext()
    }

    if (removeSuperAdminisError) {
      toast({
        title: "Error",
        status: "error",
        description: `There is an error removing this ${getOrganizationRoleLabel(
          OrganizationRole.Superadmin,
        )}. Please, try again`,
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    updateOrganizationAdminsIsSuccess,
    updateOrganizationAdminsIsError,
    removeSuperAdminisSuccess,
    removeSuperAdminisError,
  ])

  const handleDeleteAdmin = (memberId: string) => {
    updateOrganizationAdminsMutate({
      input: {
        id: organizationId,
        remove: [memberId],
      },
    })
  }

  const handleDeleteSuperAdmin = (memberId: string) => {
    removeSuperAdminMutate({
      input: {
        organizationId: organizationId,
        accountId: memberId,
      },
    })
  }

  const handleAddAdmin = () => {
    onClose()
    refetch()
    refetchContext()
  }

  const handleChangeRole = (address = "", role = OrganizationRole.Admin) => {
    setAddress(address)
    setRole(role)
    onOpen()
  }

  const showAddButton = !isReadOnly && hasAdmins && hasSuperAdminRole
  const showDeleteButton = !isReadOnly && hasSuperAdminRole
  const showChangeRoleButton = !isReadOnly && hasSuperAdminRole

  return (
    <CardContainer
      isTableView
      subTitle={
        isReadOnly
          ? "Admins can only update settings on the Tally page - not the smart contracts"
          : undefined
      }
      title="Admins"
      topRight={
        showAddButton ? (
          <Box>
            <Button variant="secondary" onClick={() => handleChangeRole()}>
              Add new admin
            </Button>
          </Box>
        ) : null
      }
    >
      <Stack as="section" mt={{ base: 5, lg: 1 }} width="100%">
        <Box py={2}>
          {isLoading && <AdminsLoading />}

          {!isLoading && hasAdmins ? (
            <AdminList
              admins={admins}
              showChangeRoleButton={showChangeRoleButton}
              showDeleteButton={showDeleteButton}
              onChangeRole={handleChangeRole}
              onDelete={handleDeleteAdmin}
              onDeleteSuperAdmin={handleDeleteSuperAdmin}
            />
          ) : null}

          {!isLoading && !hasAdmins ? <AdminsEmptyList /> : null}
        </Box>
        <EditAdminRoleModal
          address={address}
          isOpen={isOpen}
          organizationId={organizationId}
          role={role}
          onClose={onClose}
          onSave={handleAddAdmin}
        />
      </Stack>
    </CardContainer>
  )
}

type AdminListProps = {
  admins: Member[]
  showChangeRoleButton: boolean
  showDeleteButton: boolean
  onDelete?: (accountId: string) => void
  onChangeRole?: (address: string, role: OrganizationRole) => void
  onDeleteSuperAdmin?: (memberId: string) => void
}
export const AdminList: FC<AdminListProps> = ({
  admins,
  showChangeRoleButton,
  showDeleteButton,
  onDelete,
  onChangeRole,
  onDeleteSuperAdmin,
}) => {
  const me = useMe()

  const byAuthUser = (a: Member, b: Member): number => {
    if (me && isSameAddress(a.account.address, me?.address)) return -1

    if (me && isSameAddress(b.account.address, me?.address)) return 1

    return 0
  }

  const byRole = (a: Member, b: Member): number => {
    if (a.role === b.role) return 0

    if (a.role === OrganizationRole.Superadmin) return -1
    if (b.role === OrganizationRole.Superadmin) return 1

    return 0
  }

  const adminsList = admins.sort(byAuthUser).sort(byRole)

  return (
    <Flex align="stretch" direction="column" paddingBottom={0} px={4}>
      {adminsList.sort(byAuthUser).map((admin) => {
        const showSuperAdminDeleteButton =
          showDeleteButton &&
          isEqual(admin.account.id.toLowerCase(), me?.id?.toLowerCase() ?? "")

        return (
          <OrganizationAdmin
            key={`admin_member_${admin.id}`}
            member={admin}
            showChangeRoleButton={
              showChangeRoleButton &&
              !isSameAddress(me?.address, admin.account.address) &&
              admin.role !== OrganizationRole.Superadmin
            }
            showDeleteButton={
              showDeleteButton &&
              !isSameAddress(me?.address, admin.account.address) &&
              admin.role !== OrganizationRole.Superadmin
            }
            showItIsYouBadge={isSameAddress(me?.address, admin.account.address)}
            showSuperAdminDeleteButton={showSuperAdminDeleteButton}
            onChangeRole={() =>
              onChangeRole
                ? onChangeRole(admin.account.address, admin.role)
                : undefined
            }
            onDelete={() => (onDelete ? onDelete(admin.id) : undefined)}
            onDeleteSuperAdmin={() =>
              onDeleteSuperAdmin
                ? onDeleteSuperAdmin(admin.account.id)
                : undefined
            }
          />
        )
      })}
    </Flex>
  )
}

export const AdminsEmptyList: FC = () => {
  return (
    <Flex
      align="center"
      bg="gray.50"
      direction="column"
      h={16}
      justify="center"
      mx={4}
    >
      <Text color="gray.600" fontSize="md">
        If you want to apply to be a Tally metadata admin of this DAO, please
        reach out on Discord
      </Text>
    </Flex>
  )
}

type EditAdminRoleModalProps = {
  address?: string
  role?: OrganizationRole
  organizationId: string
  isOpen: boolean
  onClose: () => void
  onSave: () => void
}
const EditAdminRoleModal: FC<EditAdminRoleModalProps> = ({
  address,
  role,
  organizationId,
  isOpen,
  onClose,
  onSave,
}) => {
  const cancelRef = React.useRef(null)

  const isAddNewAdmin = !Boolean(address)

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg">
            {isAddNewAdmin ? "Add new admin" : "Change role"}
          </AlertDialogHeader>

          <AlertDialogCloseButton />
          <AlertDialogBody>
            <OrganizationAddAdminForm
              address={address}
              organizationId={organizationId}
              role={role}
              onCancel={onClose}
              onSave={onSave}
            />
          </AlertDialogBody>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}

export const AdminsLoading = () => {
  return (
    <>
      {generateArrayOfNumbers(3).map((number) => (
        <Skeleton
          key={`governance_card_skeleton_${number}`}
          h="5rem"
          mb={1}
          w="full"
        />
      ))}
    </>
  )
}

export default OrganizationAdminList
