import * as Yup from "yup"
import { isAddress } from "@ethersproject/address"
import isEqual from "lodash.isequal"

import { SocialUrl } from "organization/types/social"
import type {
  GovernorMetadataQuery,
  GovernorMetadataQueryVariables,
} from "query/graphql"
import { GovernorMetadataDocument, OrganizationRole } from "query/graphql"
import { fetcher, GrpcErrorType } from "common/helpers/fetcher"
import { EditMode } from "common/constants/edit-mode"

export const MAX_FILE_SIZE_MB = 2

const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"]

const NAME_MAX_LENGTH = 50
const DESCRIPTION_MAX_LENGTH = 240

export const updateOrganizationValidationSchema = Yup.object({
  name: Yup.string()
    .trim()
    .required("Please enter a name")
    .max(
      NAME_MAX_LENGTH,
      `The DAO name cannot be more than ${NAME_MAX_LENGTH} characters`,
    ),
  website: Yup.string()
    .trim()
    .url("Please enter a valid URL, e.g. https://example.com"),
  description: Yup.string()
    .trim()
    .max(
      DESCRIPTION_MAX_LENGTH,
      `The description cannot be more than ${DESCRIPTION_MAX_LENGTH} characters`,
    ),
  slug: Yup.string()
    .trim()
    .required("Please enter a slug")
    .matches(
      /^[a-z0-9]+(?:-[a-z0-9]+)*$/,
      "Slug should be lower case, contain letters, number or dashes, e.g. awesome-dao",
    )
    .test(
      "slug_taken",
      "This slug is already taken",
      async (value, { parent }) => {
        if (value) {
          if (parent.editMode === EditMode.Governance) {
            try {
              const governanceSlug = await fetcher.gql<
                GovernorMetadataQuery,
                GovernorMetadataQueryVariables
              >({
                query: GovernorMetadataDocument,
                variables: {
                  input: {
                    slug: value,
                  },
                },
                omittedErrors: [GrpcErrorType.NotFound],
              })

              if (
                governanceSlug &&
                governanceSlug.governor &&
                !isEqual(governanceSlug.governor.id, parent.governanceId)
              )
                return false
            } catch (err) {}
          }
        }

        return true
      },
    ),
  social: Yup.object({
    twitter: Yup.string()
      .trim()
      .transform((value) => `${SocialUrl.Twitter}${value}`)
      .url("Please enter a valid Twitter URL"),
    discord: Yup.string()
      .trim()
      .transform((value) => `${SocialUrl.Discord}${value}`)
      .url("Please enter a valid Discord URL"),
    telegram: Yup.string()
      .trim()
      .transform((value) => `${SocialUrl.Telegram}${value}`)
      .url("Please enter a valid Telegram URL"),
    others: Yup.array().of(
      Yup.object().shape({
        value: Yup.string()
          .trim()
          .required("Please enter a URL")
          .url("Please enter a valid URL, e.g. https://example.com"),
        label: Yup.string()
          .trim()
          .required("Please enter a name or description"),
      }),
    ),
  }),
  contact: Yup.object({
    name: Yup.string().trim(),
    email: Yup.string().trim().email("Please enter a valid email address"),
    twitter: Yup.string()
      .trim()
      .transform((value) => `${SocialUrl.Twitter}${value}`)
      .url("Please enter a valid Twitter URL"),
    discord: Yup.string().trim(),
  }),
})

export const uploadLogoOrganizationValidationSchema = Yup.object({
  organizationImage: Yup.mixed()
    .test("fileSize", "File size is too large", (value) => {
      if (value && value instanceof File)
        return value.size <= MAX_FILE_SIZE_MB * 1024 * 1024

      return true
    })
    .test("fileType", "Unsupported file format", (value) => {
      if (value && value instanceof File)
        return SUPPORTED_FORMATS.includes(value.type)

      return true
    }),
})

export const addAdminInOrganizationValidationSchema = Yup.object({
  address: Yup.string()
    .trim()
    .test("isAddress", "Must be a valid ETH address", (value) =>
      isAddress(value || ""),
    ),
  role: Yup.string()
    .trim()
    .test(
      "isValidRole",
      "Must be a valid role",
      (value) =>
        value === OrganizationRole.Superadmin ||
        value === OrganizationRole.Admin,
    ),
})
