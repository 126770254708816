import type { FC } from "react"
import React from "react"
import {
  Flex,
  Stack,
  Text,
  Button,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogCloseButton,
  useDisclosure,
  Badge,
  HStack,
} from "@chakra-ui/react"
import { isAddress } from "@ethersproject/address"

import type { Member } from "query/graphql"
import { isSameAddress } from "web3/helpers/address"
import UserAvatar from "common/components/UserAvatar"
import { shortString } from "common/helpers/string"
import { useDevice } from "common/hooks/useDevice"
import { useMe } from "user/providers/MeProvider"
import CopyButton from "common/components/CopyButton"
import { getOrganizationRoleLabel } from "organization/helpers/role"

type Props = {
  member: Member
  onDelete: () => void
  onDeleteSuperAdmin: () => void
  onChangeRole: () => void
  showItIsYouBadge: boolean
  showChangeRoleButton: boolean
  showDeleteButton: boolean
  showSuperAdminDeleteButton: boolean
}
const OrganizationAdmin: FC<Props> = ({
  member,
  showItIsYouBadge,
  showChangeRoleButton,
  showDeleteButton,
  showSuperAdminDeleteButton,
  onChangeRole,
  onDelete,
  onDeleteSuperAdmin,
}) => {
  const { account, role } = member

  const { onLargeDevice, onLittleDevice } = useDevice()

  const twitterUsername = account?.twitter
  const isVerifiedByTwitter = Boolean(twitterUsername)

  return (
    <Stack direction="row" justifyContent="space-between">
      <HStack mb={4}>
        <Flex mr={3}>
          <UserAvatar
            address={account.address}
            isVerifiedByTwitter={isVerifiedByTwitter}
            size="md"
            src={account.picture}
          />
        </Flex>

        <Flex align="left" direction="row" justify="space-between" w="full">
          <Flex align="baseline" justify="flex-start" w="full">
            <Flex direction="column" justify="start">
              <Text display={onLargeDevice} textStyle="body.bold.lg">
                {account.address}
              </Text>
              <Text display={onLittleDevice} textStyle="body.bold.lg">
                {isAddress(account.address)
                  ? shortString(account.address)
                  : account.address}
              </Text>
              <Stack align="baseline" direction="row" pt={1} spacing={4}>
                <Badge textStyle="body.regular.md">
                  {getOrganizationRoleLabel(role)}
                </Badge>
                {showItIsYouBadge ? (
                  <Text
                    display={{ base: "none", sm: "block" }}
                    textStyle="body.bold.md"
                  >
                    <Badge colorScheme="green" textStyle="body.regular.md">
                      This is you
                    </Badge>
                  </Text>
                ) : null}
              </Stack>
            </Flex>
            <CopyButton
              ml={2}
              size={8}
              tooltipLabel="address"
              value={account?.address}
            />
          </Flex>
        </Flex>
      </HStack>

      <Stack direction="row" spacing={2}>
        {showChangeRoleButton ? (
          <Button size="sm" variant="secondary" onClick={onChangeRole}>
            Change role
          </Button>
        ) : null}
        {showDeleteButton ? (
          <DeleteButton account={account} onClick={onDelete} />
        ) : null}
        {showSuperAdminDeleteButton ? (
          <DeleteButton account={account} onClick={onDeleteSuperAdmin} />
        ) : null}
      </Stack>
    </Stack>
  )
}

export default OrganizationAdmin

type DeleteButtonProps = {
  onClick: () => void
  account: Member["account"]
}
const DeleteButton: FC<DeleteButtonProps> = ({ onClick, account }) => {
  const { isOpen, onOpen, onClose: onCloseAlert } = useDisclosure()
  const cancelRef = React.useRef(null)
  const me = useMe()

  const handleOnOpenConfirm = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    event.stopPropagation()

    onOpen()
  }

  const handleOnConfirm = () => {
    onCloseAlert()
    onClick()
  }

  const addressLabel = isAddress(account.address)
    ? shortString(account.address)
    : account.address

  const isMeAddress = isSameAddress(me?.address, account.address)

  return (
    <>
      <Button
        colorScheme="red"
        size="sm"
        variant="outline"
        onClick={handleOnOpenConfirm}
      >
        Delete
      </Button>

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onCloseAlert}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg">
              Delete Tally metadata admin
            </AlertDialogHeader>
            <AlertDialogCloseButton />
            <AlertDialogBody>
              Are you sure to delete{" "}
              <Text as="span" fontWeight="bold">
                {addressLabel}
              </Text>{" "}
              account?{" "}
              {isMeAddress
                ? "This is you! Your Tally metadata admin access will be lost"
                : "You can add it back later"}
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onCloseAlert}>
                Cancel
              </Button>
              <Button colorScheme="red" ml={3} onClick={handleOnConfirm}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}
