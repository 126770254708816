export const shortString = (
  value: string,
  initialLength = 6,
  endLength = -4,
): string => `${value.slice(0, initialLength)}...${value.slice(endLength)}`

export const subString = (
  value: string,
  endLength = 16,
  appendText = "...",
): string => {
  if (value === undefined) return ""

  return value.length > endLength
    ? value.substring(0, endLength) + appendText
    : value
}

export function firstLetterToUpper(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1).toLocaleLowerCase()
}

export const getValidJsonString = (input: string) => {
  return input.replace(/'/g, '"')
}

export const pluralize = (
  number: number,
  singularText: string,
  pluralText: string,
): string => {
  return number === 1 ? singularText : pluralText
}
