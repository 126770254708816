import type { FC } from "react"
import React, { useState } from "react"
import type { ButtonProps, IconProps } from "@chakra-ui/react"
import { Button, Icon, Tooltip, useClipboard } from "@chakra-ui/react"

import { Copy } from "ui/components/icons/font-awesome/Copy"
import Check from "ui/components/icons/Check"

type Props = {
  size: number
  iconProps?: IconProps
  value: string
  tooltipLabel?: string
}

type ButtonPropsChakra = Omit<ButtonProps, "size">

const CopyButton: FC<ButtonPropsChakra & Props> = ({
  size,
  iconProps,
  value,
  tooltipLabel,
  ...buttonProps
}) => {
  const { onCopy } = useClipboard(value)
  const [pressButton, setPressButton] = useState(false)

  const handleClick = () => {
    onCopy()

    setPressButton(true)
    setTimeout(() => {
      setPressButton(false)
    }, 1500)
  }

  return (
    <Tooltip label={tooltipLabel ? `Copy ${tooltipLabel}` : "Copy"}>
      <Button
        alignItems="center"
        bg="transparent"
        display="flex"
        h={size}
        justifyContent="center"
        minW={size}
        rounded="md"
        w={size}
        onClick={handleClick}
        {...buttonProps}
      >
        <Icon
          as={pressButton ? Check : Copy}
          fill="gray.500"
          h={3}
          w={3}
          {...iconProps}
        />
      </Button>
    </Tooltip>
  )
}

export default CopyButton
