import { getDateFromBlocks, getDateFromSeconds } from "common/helpers/date"
import type { Chain, Governor, GovernorParameters } from "query/graphql"
import { GovernorKind, GovernorType } from "query/graphql"
import type { ChainId } from "web3/constants/chains"
import chains from "web3/constants/chains"
import { parseAccountId } from "web3/helpers/transformers"

export const getGovernanceIds = <
  T extends Pick<Governor | Governor, "id" | "type" | "kind">,
>(
  governances: T[],
): string[] => {
  if (!governances) {
    return []
  }

  const byKind = (
    a: Pick<Governor | Governor, "id" | "kind">,
    b: Pick<Governor | Governor, "id" | "kind">,
  ) => {
    if (a.kind === GovernorKind.Single) return -1
    if (b.kind === GovernorKind.Single) return 1
    if (a.kind === GovernorKind.Multiprimary) return -1
    if (b.kind === GovernorKind.Multiprimary) return 1

    return 0
  }

  const governanceIds = governances
    .sort(byKind)
    .map((governance) => governance.id)

  return governanceIds
}

export const byGovernancePriority = <T extends Pick<Governor, "kind">>(
  a: T,
  b: T,
): number => {
  const priority: Record<GovernorKind | string, number> = {
    [GovernorKind.Single]: 1,
    [GovernorKind.Multiprimary]: 2,
    [GovernorKind.Multisecondary]: 3,
    [GovernorKind.Hub]: 4,
    [GovernorKind.Spoke]: 5,
    default: 6,
  }

  const priorityA = priority[a.kind] || priority["default"]
  const priorityB = priority[b.kind] || priority["default"]

  if (priorityA < priorityB) return -1
  if (priorityA > priorityB) return 1

  return 0
}

export const getMainnetChainIds = (): Array<ChainId> => {
  const allTheMainnetChainIds: ChainId[] = []

  const sortedChains = sortChains(chains)

  Object.keys(sortedChains).forEach((key) => {
    if (Object.prototype.hasOwnProperty.call(chains, key)) {
      const chain = chains[key as ChainId]

      // isTestnet === false might include some testnet chains that the BE wants to highlight
      if (chain.isTestnet === false) {
        allTheMainnetChainIds.push(key as ChainId)
      }
    }
  })

  return allTheMainnetChainIds
}
const sortChains = (chains: { [key in ChainId]: Chain }): {
  [key in ChainId]: Chain
} => {
  const chainEntries = Object.entries(chains)

  const customSort = (
    [, aValue]: [string, Chain],
    [, bValue]: [string, Chain],
  ) => {
    const aIdString = aValue.id.toString()
    const bIdString = bValue.id.toString()

    if (aIdString === "eip155:1" && bIdString !== "eip155:1") {
      return -1
    } else if (aIdString !== "eip155:1" && bIdString === "eip155:1") {
      return 1
    }

    return aValue.mediumName.localeCompare(bValue.mediumName)
  }

  const sortedEntries = chainEntries.sort(customSort)

  const sortedChains: { [key in ChainId]: Chain } = sortedEntries.reduce(
    (accumulator, [key, value]) => ({
      ...accumulator,
      [key as ChainId]: value,
    }),
    {} as { [key in ChainId]: Chain },
  )

  return sortedChains
}

export const getParameterDatesLabel = (
  parameters: GovernorParameters,
  chain:
    | {
        blockTime: number
      }
    | null
    | undefined,
): {
  votingPeriodLabel: string | null
  votingDelayLabel: string | null
} => {
  const { votingPeriod, votingDelay } = parameters

  // If clock mode is timestamp
  if (parameters.clockMode?.includes("mode=timestamp")) {
    const votingPeriodLabel =
      Number(votingPeriod) === 0
        ? "None"
        : getDateFromSeconds(Number(votingPeriod))
    const votingDelayLabel =
      Number(votingDelay) === 0
        ? "None"
        : getDateFromSeconds(Number(votingDelay))

    return {
      votingPeriodLabel,
      votingDelayLabel,
    }
  }

  // Otherwise, clock mode is block
  const votingPeriodLabel = chain
    ? getDateFromBlocks(Number(votingPeriod), chain.blockTime)
    : null
  const votingDelayLabel = chain
    ? getDateFromBlocks(Number(votingDelay), chain.blockTime)
    : null

  return {
    votingPeriodLabel,
    votingDelayLabel,
  }
}

export const isMultiChainGovernors = (governors?: Governor[]): boolean => {
  if (!governors || governors.length === 0) {
    return false
  }

  const isMultiChain =
    governors.reduce((acc: string[], governor) => {
      const { chainId } = parseAccountId(governor.id)

      if (!acc.includes(chainId)) {
        acc.push(chainId)
      }

      return acc
    }, []).length > 1

  return isMultiChain
}

export const getGovernorsByKind = (
  governors: Governor[],
  governorKinds: GovernorKind[],
) => {
  const governorsByKind = governors.filter((governor) =>
    governorKinds.includes(governor.kind),
  )

  return governorsByKind
}

export const getGovernorHub = (governors: Governor[]): Governor | undefined => {
  const governorHub = governors.find(
    (governor) => governor.type === GovernorType.Hub,
  )

  return governorHub
}

export const isMainGovernor = (governor: Governor) => {
  return [
    GovernorKind.Single,
    GovernorKind.Multiprimary,
    GovernorKind.Multisecondary,
  ].includes(governor.kind)
}
