import { createIcon } from "@chakra-ui/icons"

const BraveIcon = createIcon({
  displayName: "BraveIcon",
  viewBox: "0 0 218 256",
  path: (
    <>
      <defs>
        <linearGradient id="a" x1="0%" x2="100%" y1="50.706%" y2="50.706%">
          <stop offset="0%" stop-color="#F50" />
          <stop offset="40.988%" stop-color="#F50" />
          <stop offset="58.198%" stop-color="#FF2000" />
          <stop offset="100%" stop-color="#FF2000" />
        </linearGradient>
        <linearGradient id="c" x1="2.148%" x2="100%" y1="50.706%" y2="50.706%">
          <stop offset="0%" stop-color="#FF452A" />
          <stop offset="100%" stop-color="#FF2000" />
        </linearGradient>
        <path
          d="M170.272 25.336 147.968 0H69.632L47.328 25.336s-19.584-5.447-28.832 3.813c0 0 26.112-2.36 35.088 12.255 0 0 24.208 4.63 27.472 4.63 3.264 0 10.336-2.724 16.864-4.902 6.528-2.179 10.88-2.195 10.88-2.195s4.352.016 10.88 2.195c6.528 2.178 13.6 4.902 16.864 4.902 3.264 0 27.472-4.63 27.472-4.63 8.976-14.615 35.088-12.255 35.088-12.255-9.248-9.26-28.832-3.813-28.832-3.813"
          id="b"
        />
      </defs>
      <g fill="none" fillRule="evenodd" transform="translate(.114)">
        <path
          d="m209.984 61.285 5.984-14.707s-7.616-8.17-16.864-17.43c-9.248-9.259-28.832-3.812-28.832-3.812L147.968 0H69.632L47.328 25.336s-19.584-5.447-28.832 3.813c-9.248 9.26-16.864 17.43-16.864 17.43l5.984 14.706L0 83.072s22.4 84.946 25.024 95.32c5.168 20.425 8.704 28.323 23.392 38.672 14.688 10.349 41.344 28.323 45.696 31.046 4.352 2.724 9.792 7.363 14.688 7.363 4.896 0 10.336-4.64 14.688-7.363 4.352-2.723 31.008-20.697 45.696-31.046 14.688-10.35 18.224-18.247 23.392-38.673C195.2 168.018 217.6 83.072 217.6 83.072z"
          fill="url(#a)"
        />
        <path
          d="M164.016 41.404s28.688 34.723 28.688 42.145c0 7.421-3.608 9.38-7.237 13.238l-21.51 22.87c-2.036 2.164-6.273 5.445-3.78 11.35 2.492 5.905 6.168 13.419 2.08 21.04-4.089 7.62-11.093 12.708-15.58 11.867-4.489-.842-15.029-6.357-18.905-8.876-3.876-2.52-16.16-12.663-16.16-16.545 0-3.88 12.698-10.85 15.044-12.432 2.347-1.583 13.047-7.712 13.266-10.117.219-2.406.136-3.111-3.022-9.055-3.158-5.944-8.845-13.875-7.898-19.153.946-5.277 10.118-8.02 16.663-10.496 6.545-2.474 19.146-7.148 20.72-7.875 1.575-.727 1.168-1.42-3.601-1.872-4.768-.452-18.3-2.251-24.402-.548-6.1 1.702-16.524 4.293-17.368 5.667-.844 1.373-1.589 1.42-.722 6.158.867 4.739 5.33 27.477 5.764 31.516.433 4.039 1.28 6.709-3.068 7.705-4.35.995-11.672 2.724-14.188 2.724s-9.838-1.729-14.187-2.724c-4.35-.996-3.503-3.666-3.07-7.705.434-4.039 4.898-26.777 5.765-31.516.867-4.739.122-4.785-.722-6.158-.844-1.374-11.268-3.965-17.369-5.667-6.1-1.703-19.633.096-24.401.548-4.769.453-5.176 1.145-3.602 1.872 1.575.727 14.177 5.4 20.72 7.875 6.546 2.475 15.718 5.22 16.665 10.496.946 5.278-4.741 13.21-7.899 19.153-3.158 5.944-3.241 6.65-3.022 9.055.219 2.405 10.92 8.534 13.266 10.117 2.346 1.583 15.044 8.552 15.044 12.432 0 3.882-12.284 14.026-16.16 16.545-3.876 2.52-14.416 8.034-18.904 8.876-4.488.84-11.492-4.246-15.58-11.867-4.089-7.621-.412-15.135 2.08-21.04 2.491-5.905-1.745-9.186-3.78-11.35l-21.511-22.87c-3.629-3.858-7.237-5.817-7.237-13.238 0-7.422 28.688-42.145 28.688-42.145s24.208 4.63 27.472 4.63c3.264 0 10.336-2.724 16.864-4.902 6.528-2.179 10.88-2.195 10.88-2.195s4.352.016 10.88 2.195c6.528 2.178 13.6 4.902 16.864 4.902 3.264 0 27.472-4.63 27.472-4.63M142.51 174.228c1.775 1.113.692 3.212-.925 4.357-1.618 1.145-23.357 18-25.467 19.862-2.11 1.864-5.21 4.94-7.318 4.94-2.108 0-5.209-3.076-7.318-4.94-2.11-1.863-23.849-18.717-25.467-19.862-1.618-1.145-2.7-3.244-.925-4.357 1.777-1.113 7.333-3.922 15-7.894 7.665-3.972 17.219-7.349 18.71-7.349 1.491 0 11.045 3.377 18.711 7.349 7.666 3.972 13.222 6.781 14.999 7.894"
          fill="#FFF"
        />
        <use fill="url(#c)" xlinkHref="#b" />
      </g>
    </>
  ),
})

export default BraveIcon
