import React from "react"
import { createIcon } from "@chakra-ui/icons"

const TallyBlack = createIcon({
  displayName: "TallyBlack",
  viewBox: "0 0 706 160",
  path: (
    <g clipPath="url(#clip0_3_570)">
      <path
        clip-rule="evenodd"
        d="M96.8981 89.6686V64.4458C96.8981 62.7933 96.0031 61.2671 94.5686 60.4523L14.4246 15.1934V10.5688C14.4246 8.81311 16.318 7.71147 17.8442 8.57212L102.108 56.172C103.554 56.9868 104.437 58.513 104.437 60.1654V90.0129C104.437 91.7686 102.544 92.8703 101.018 92.0096L96.8867 89.6801L96.8981 89.6686ZM75.6343 101.97L68.0835 97.7014V148.526L72.2146 150.855C73.7408 151.716 75.6343 150.614 75.6343 148.859V101.959V101.97Z"
        fill="#1C1C1C"
        fill-rule="evenodd"
      />
      <path
        clip-rule="evenodd"
        d="M111.346 81.4062V56.1833C111.346 54.5309 110.451 53.0046 109.016 52.1899L28.8835 6.93097V2.29492C28.8835 0.539182 30.777 -0.562455 32.3032 0.298199L116.567 47.8981C118.013 48.7128 118.896 50.2391 118.896 51.8915V81.739C118.896 83.4947 117.003 84.5964 115.477 83.7357L111.346 81.4062ZM88.7965 109.223L82.531 105.711V146.609L86.5473 148.859C88.0736 149.719 89.967 148.606 89.967 146.862V111.219C89.967 110.393 89.5195 109.624 88.7965 109.223Z"
        fill="#1C1C1C"
        fill-rule="evenodd"
      />
      <path
        d="M90.0244 68.2785V98.1259C90.0244 99.8817 88.1309 100.983 86.6047 100.123L62.9195 86.7424C62.1507 86.3063 61.2097 86.8571 61.2097 87.7407V156.983C61.2097 158.739 59.3163 159.841 57.79 158.98L31.1671 143.936C29.7212 143.121 28.8376 141.595 28.8376 139.942V68.8408C28.8376 68.0145 28.3901 67.2457 27.6671 66.8441L2.3295 52.5343C0.883604 51.7195 0 50.1933 0 48.5408V18.6934C0 16.9376 1.89344 15.836 3.41966 16.6966L87.6834 64.2965C89.1293 65.1113 90.0129 66.6375 90.0129 68.29L90.0244 68.2785Z"
        fill="#1C1C1C"
      />
      <path
        d="M496.758 151.211C499.294 151.211 501.348 149.157 501.348 146.621V114.192C501.348 111.656 499.294 109.601 496.758 109.601H464.81C463.548 109.601 462.515 108.569 462.515 107.306V47.0262C462.515 44.4901 460.461 42.436 457.925 42.436H418.542C416.006 42.436 413.951 44.4901 413.951 47.0262V146.621C413.951 149.157 416.006 151.211 418.542 151.211H496.758Z"
        fill="#1C1C1C"
      />
      <path
        d="M597.867 151.211C600.403 151.211 602.458 149.157 602.458 146.621V114.192C602.458 111.656 600.403 109.601 597.867 109.601H565.92C564.658 109.601 563.625 108.569 563.625 107.306V47.0262C563.625 44.4901 561.571 42.436 559.035 42.436H519.651C517.115 42.436 515.061 44.4901 515.061 47.0262V146.621C515.061 149.157 517.115 151.211 519.651 151.211H597.867Z"
        fill="#1C1C1C"
      />
      <path
        d="M297.58 47.0145V79.4439C297.58 81.98 295.525 84.0341 292.989 84.0341H264.599C263.337 84.0341 262.304 85.0668 262.304 86.3291V146.609C262.304 149.145 260.25 151.199 257.714 151.199H218.331C215.795 151.199 213.74 149.145 213.74 146.609V86.3291C213.74 85.0668 212.708 84.0341 211.445 84.0341H183.055C180.519 84.0341 178.465 81.98 178.465 79.4439V47.0145C178.465 44.4784 180.519 42.4243 183.055 42.4243H292.978C295.514 42.4243 297.568 44.4784 297.568 47.0145H297.58Z"
        fill="#1C1C1C"
      />
      <path
        d="M402.04 145.037L365.594 45.4423C364.929 43.6292 363.207 42.4243 361.28 42.4243H328.207C326.28 42.4243 324.558 43.6292 323.893 45.4423L287.447 145.037C286.345 148.032 288.572 151.199 291.762 151.199H330.285C332.339 151.199 334.14 149.834 334.703 147.86L337.239 138.886C337.514 137.899 338.421 137.211 339.442 137.211H350.034C351.066 137.211 351.962 137.888 352.237 138.886L354.773 147.86C355.335 149.834 357.137 151.199 359.191 151.199H397.714C400.904 151.199 403.119 148.032 402.029 145.037H402.04ZM352.203 109.28H337.296C335.701 109.28 334.588 107.696 335.139 106.193L342.598 85.8816C343.332 83.8734 346.166 83.8734 346.912 85.8816L354.371 106.193C354.922 107.696 353.809 109.28 352.214 109.28H352.203Z"
        fill="#1C1C1C"
      />
      <path
        d="M701.41 42.436H663.954C662.049 42.436 660.339 43.6065 659.662 45.3852L648.566 74.4524C647.82 76.4261 645.031 76.4261 644.274 74.4524L633.177 45.3852C632.5 43.6065 630.79 42.436 628.886 42.436H591.43C587.895 42.436 585.692 46.2573 587.448 49.3213L621.518 108.431C621.92 109.131 622.127 109.923 622.127 110.726V146.621C622.127 149.157 624.181 151.211 626.717 151.211H666.1C668.636 151.211 670.69 149.157 670.69 146.621V110.726C670.69 109.923 670.897 109.131 671.299 108.431L705.369 49.3213C707.136 46.2573 704.921 42.436 701.387 42.436H701.41Z"
        fill="#1C1C1C"
      />
    </g>
  ),
})

export default TallyBlack
