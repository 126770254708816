import { useEffect, useState, type FC, type ReactNode } from "react"
import type { CardProps } from "@chakra-ui/react"
import {
  Card as ChakraCard,
  CardHeader,
  CardBody,
  Text,
  Flex,
  Icon,
  Collapse,
} from "@chakra-ui/react"

import { AngleUp } from "ui/components/icons/font-awesome/AngleUp"
import { AngleDown } from "ui/components/icons/font-awesome/AngleDown"

type Props = {
  title?: string
  elementTitle?: ReactNode
  subTitle?: string
  children: ReactNode
  topRight?: ReactNode
  isTableView?: boolean
  isFullHeightView?: boolean
  isCollapsed?: boolean
  displayCollapseButton?: boolean
  pb?: number
}
const CardContainer: FC<Props & CardProps> = ({
  title,
  elementTitle,
  subTitle,
  children,
  topRight,
  isTableView = false,
  isFullHeightView = false,
  isCollapsed = false,
  displayCollapseButton = false,
  pb = 4,
  ...cardProps
}) => {
  const [displayBody, setDisplayBody] = useState(!isCollapsed)

  useEffect(() => {
    setDisplayBody(!isCollapsed)
  }, [isCollapsed])

  return (
    <ChakraCard mb={2} mt={0} variant="outline" {...cardProps}>
      {title || elementTitle ? (
        <CardHeader
          borderBottom="gray.light"
          mb={{ base: 0, sm: isFullHeightView ? 0 : 2 }}
          pb={2}
          pt={2}
          px={{ base: 3, md: 4 }}
          onClick={() => {
            if (!displayCollapseButton) return
            setDisplayBody(!displayBody)
          }}
        >
          <Flex
            align="center"
            direction={{ base: "column", sm: "row" }}
            justify="space-between"
          >
            <Flex
              align="center"
              direction="row"
              justify="space-between"
              w="full"
            >
              <Flex direction="column" justify="space-between" w="full">
                <Text as="h6" color="gray.800" fontSize="md">
                  {title ? title : elementTitle}
                </Text>
                {subTitle ? <Text color="gray.900">{subTitle}</Text> : null}
              </Flex>
              {displayCollapseButton ? (
                <Icon as={displayBody ? AngleUp : AngleDown} h={4} w={4} />
              ) : null}
            </Flex>
            {topRight ? topRight : null}
          </Flex>
        </CardHeader>
      ) : null}
      <CardBody
        display={displayBody ? "auto" : "none"}
        pb={pb}
        pt={{ base: 0, sm: isTableView ? 0 : 4 }}
        px={isTableView ? 0 : "auto"}
        width="full"
      >
        <Collapse animateOpacity in={displayBody}>
          {children}
        </Collapse>
      </CardBody>
    </ChakraCard>
  )
}

export default CardContainer
