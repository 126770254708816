import type { Breadcrumb } from "common/constants/breadcrumb"

export function getWhitelabelBreadcrumb(breadcrumb: Breadcrumb) {
  const nextElements = breadcrumb.slice(1, breadcrumb.length)
  const firstElementNewLabel = { ...nextElements[0], label: "Home" }
  const newElements = [
    firstElementNewLabel,
    ...nextElements.slice(1, nextElements.length),
  ]

  return newElements
}

export function getWhiteLabelRoute(route: string) {
  // if (process.env.NODE_ENV === "development") return route

  const isAccountId = route.includes("eip")
  const regex = isAccountId ? /\/gov\/[a-zA-Z0-9:]{1,64}/gi : /\/gov\/[\w-]+/g

  return route.replace(regex, "/dao")
}
