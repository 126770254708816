import type { ReactElement, ReactNode } from "react"
import { useEffect, useState } from "react"

export function AwaitRender({
  ms,
  loader,
  children,
}: {
  ms: number
  loader?: ReactElement
  children: ReactNode
}) {
  const [allowRender, setAllowRender] = useState(false)

  useEffect(() => {
    if (allowRender) return

    console.time("before timeout")
    setTimeout(() => {
      console.time("after timeout")
      setAllowRender(true)
    }, ms)
  }, [allowRender, ms])

  if (!allowRender) return loader ?? null

  return <>{children}</>
}
