import type { FC } from "react"
import React from "react"
import type {
  FormikErrors,
  FormikValues,
  FormikHandlers,
  FormikTouched,
} from "formik"
import { getIn } from "formik"
import type { FlexProps, InputProps } from "@chakra-ui/react"
import {
  Stack,
  Text,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Flex,
  InputGroup,
  InputRightElement,
  InputLeftAddon,
  HStack,
  Icon,
} from "@chakra-ui/react"
import get from "lodash.get"

import Check from "ui/components/icons/Check"
import CircleMark from "ui/components/icons/CircleMark"

type HandleChange = Pick<FormikHandlers, "handleChange">["handleChange"]

type Props = {
  onChange: HandleChange
  errors: FormikErrors<FormikValues>
  values: FormikValues
  touched: FormikTouched<FormikValues>
  placeholder: string
  name: string
  label?: string
  dataCy?: string
  inputProps?: InputProps
  isDisabled?: boolean
  isReadOnly?: boolean
  shouldShowValidationIcon?: boolean
  shouldShowInvalidationIcon?: boolean
  extraLabel?: string
  leftAddOn?: string
  dataQa?: string
}

const FormInput: FC<Props & FlexProps> = ({
  onChange,
  extraLabel,
  values,
  errors,
  placeholder,
  name,
  dataCy = "",
  label,
  touched,
  inputProps,
  isDisabled = false,
  isReadOnly = false,
  shouldShowValidationIcon = false,
  shouldShowInvalidationIcon = false,
  leftAddOn,
  dataQa,
  ...flexProps
}) => {
  const error = getIn(errors, name)
  const touch = getIn(touched, name)
  const isTouched = touch !== undefined
  const hasError = error !== undefined

  const hasLabel = label != null
  const isInvalid = isTouched && hasError
  const isValid = get(values, name) && !hasError

  return (
    <Flex w="full" {...flexProps}>
      <FormControl isInvalid={isInvalid}>
        <FormLabel
          color="gray.700"
          htmlFor={name}
          mb={hasLabel ? 1 : 0}
          mr={0}
          textStyle="label"
        >
          {label}
        </FormLabel>
        <InputGroup>
          {leftAddOn ? (
            // eslint-disable-next-line react/no-children-prop
            <InputLeftAddon
              _focus={{
                boxShadow: "outline",
                borderColor: get(values, name) ? "purple.900" : "purple.700",
              }}
              bgColor="white"
              borderColor={get(values, name) ? "purple.900" : "gray.100"}
              borderRadius={3}
            >
              {leftAddOn}
            </InputLeftAddon>
          ) : null}
          <Input
            _focus={{
              boxShadow: "outline",
              borderColor: get(values, name) ? "purple.900" : "purple.700",
            }}
            _hover={{
              boxShadow: "outline",
              borderColor: get(values, name) ? "purple.900" : "purple.700",
            }}
            _placeholder={{
              color: "gray.400",
            }}
            border="gray.dark"
            borderColor={get(values, name) ? "gray.300" : "auto"}
            color="gray.700"
            data-qa={dataQa}
            id={name}
            isDisabled={isDisabled}
            isReadOnly={isReadOnly}
            name={name}
            placeholder={placeholder}
            type="text"
            value={get(values, name)}
            onChange={onChange}
            {...inputProps}
          />
          {shouldShowValidationIcon && isValid && (
            <InputRightElement>
              <Icon as={Check} bg="transparent" color="green.500" h={4} w={4} />
            </InputRightElement>
          )}
        </InputGroup>
        {extraLabel ? (
          <HStack ml={1} mt={1}>
            <Text fontSize="sm">{extraLabel}</Text>
          </HStack>
        ) : null}

        {isInvalid && (
          <Stack isInline alignItems="center" pb={1} spacing={1}>
            {shouldShowInvalidationIcon && (
              <Icon
                as={CircleMark}
                bg="transparent"
                color="green.500"
                h={4}
                mt={0.5}
                w={4}
              />
            )}
            <FormErrorMessage data-cy={`${dataCy}-error`}>
              {error}
            </FormErrorMessage>
          </Stack>
        )}
      </FormControl>
    </Flex>
  )
}

export default FormInput
