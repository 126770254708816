import type { FC } from "react"
import React from "react"
import { Icon } from "@chakra-ui/icons"

type Props = {
  height: number
  width: number
}
const CoinbaseWalletIcon: FC<Props> = ({ ...props }) => {
  return (
    <Icon viewBox="0 0 2500 2500" {...props}>
      <style type="text/css">
        {
          "\n\t.st0{fill:none;}\n\t.st1{fill-rule:evenodd;clip-rule:evenodd;fill:#0052FF;}\n\t.st2{fill-rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;}\n"
        }
      </style>
      <g id="Layer_x0020_1">
        <rect className="st0" height={2500} width={2500} y={0} />
        <path
          className="st1"
          d="M520.7,0h1458.5C2266.9,0,2500,250.8,2500,560.2v1379.6c0,309.4-233.1,560.2-520.7,560.2H520.7   C233.1,2500,0,2249.2,0,1939.8V560.2C0,250.8,233.1,0,520.7,0z"
        />
        <path
          className="st2"
          d="M1250,362.1L1250,362.1c490.4,0,887.9,397.5,887.9,887.9v0c0,490.4-397.5,887.9-887.9,887.9h0   c-490.4,0-887.9-397.5-887.9-887.9v0C362.1,759.6,759.6,362.1,1250,362.1z"
        />
        <path
          className="st1"
          d="M1031.3,966.2h437.3c36,0,65.1,31.4,65.1,70v427.5c0,38.7-29.2,70-65.1,70h-437.3c-36,0-65.1-31.4-65.1-70   v-427.5C966.2,997.6,995.4,966.2,1031.3,966.2z"
        />
      </g>
    </Icon>
  )
}

export default CoinbaseWalletIcon
