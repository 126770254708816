import React from "react"
import { createIcon } from "@chakra-ui/icons"

const WormholeLogo = createIcon({
  displayName: "WormholeLogo",
  viewBox: "0 0 697 698",
  path: (
    <svg
      fill="none"
      height="698"
      viewBox="0 0 697 698"
      width="697"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M348.57 697.919C256.246 697.86 167.716 661.089 102.404 595.672C37.0921 530.255 0.332356 441.537 0.19043 348.982C0.332336 256.424 37.0901 167.701 102.401 102.277C167.712 36.8532 256.242 0.071035 348.57 0C440.898 0.071035 529.427 36.8532 594.738 102.277C660.049 167.701 696.806 256.424 696.948 348.982C696.806 441.537 660.048 530.255 594.736 595.672C529.424 661.089 440.894 697.86 348.57 697.919ZM348.57 26.8654C263.346 26.9246 181.625 60.8726 121.337 121.261C61.0496 181.649 27.1189 263.545 26.9889 348.982C27.0717 434.43 60.989 516.349 121.288 576.741C181.587 637.132 263.335 671.054 348.57 671.054C433.804 671.054 515.552 637.132 575.852 576.741C636.151 516.349 670.067 434.43 670.15 348.982C670.02 263.545 636.089 181.649 575.801 121.261C515.514 60.8726 433.794 26.9246 348.57 26.8654V26.8654Z"
        fill="url(#paint0_linear_53_20)"
      />
      <path
        d="M392.615 642.709C316.126 642.65 242.783 612.178 188.676 557.977C134.568 503.776 104.115 430.273 103.997 353.593C104.115 276.912 134.568 203.408 188.676 149.208C242.783 95.0069 316.126 64.5345 392.615 64.4753C469.105 64.5345 542.448 95.0069 596.555 149.208C650.663 203.408 681.116 276.912 681.234 353.593C681.116 430.273 650.663 503.776 596.555 557.977C542.448 612.178 469.105 642.65 392.615 642.709ZM392.615 85.341C321.65 85.4002 253.605 113.676 203.408 163.965C153.211 214.254 124.961 282.45 124.855 353.593C124.973 424.728 153.229 492.912 203.425 543.191C253.62 593.471 321.658 621.739 392.615 621.799C463.573 621.739 531.611 593.471 581.806 543.191C632.002 492.912 660.258 424.728 660.376 353.593C660.258 282.457 632.002 214.272 581.806 163.993C531.611 113.714 463.573 85.445 392.615 85.3858V85.341Z"
        fill="url(#paint1_linear_53_20)"
      />
      <path
        d="M436.645 587.546C375.993 587.499 317.837 563.336 274.933 520.358C232.029 477.38 207.881 419.097 207.786 358.294C207.881 297.491 232.029 239.208 274.933 196.23C317.837 153.252 375.993 129.09 436.645 129.042C497.3 129.078 555.462 153.237 598.369 196.217C641.275 239.197 665.419 297.487 665.502 358.294C665.407 419.097 641.259 477.38 598.355 520.358C555.452 563.336 497.296 587.499 436.645 587.546V587.546ZM436.645 143.953C379.934 143.988 325.553 166.576 285.436 206.76C245.318 246.944 222.741 301.442 222.659 358.294C222.753 415.139 245.336 469.625 285.452 509.799C325.568 549.973 379.942 572.555 436.645 572.591C493.348 572.555 547.721 549.973 587.837 509.799C627.953 469.625 650.536 415.139 650.631 358.294C650.548 301.442 627.97 246.944 587.852 206.76C547.735 166.576 493.355 143.988 436.645 143.953V143.953Z"
        fill="url(#paint2_linear_53_20)"
      />
      <path
        d="M480.69 532.335C435.877 532.3 392.907 514.447 361.206 482.692C329.506 450.938 311.664 407.875 311.593 362.949C311.652 318.016 329.489 274.941 361.191 243.177C392.893 211.412 435.869 193.553 480.69 193.518C525.511 193.553 568.489 211.412 600.19 243.177C631.892 274.941 649.728 318.016 649.788 362.949C649.717 407.875 631.876 450.938 600.175 482.692C568.475 514.447 525.504 532.3 480.69 532.335V532.335ZM480.69 202.473C438.238 202.509 397.534 219.424 367.508 249.509C337.481 279.594 320.585 320.391 320.526 362.949C320.597 405.5 337.498 446.285 367.523 476.36C397.548 506.435 438.246 523.345 480.69 523.38C523.135 523.345 563.832 506.435 593.857 476.36C623.883 446.285 640.784 405.5 640.855 362.949C640.796 320.391 623.901 279.594 593.875 249.509C563.848 219.424 523.142 202.509 480.69 202.473Z"
        fill="url(#paint3_linear_53_20)"
      />
      <defs>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint0_linear_53_20"
          x1="701.303"
          x2="-66.9864"
          y1="619.919"
          y2="100.915"
        >
          <stop stop-color="#0F0C48" />
          <stop offset="1" stop-color="#262769" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint1_linear_53_20"
          x1="684.842"
          x2="48.3197"
          y1="578.085"
          y2="148.12"
        >
          <stop stop-color="#0F0C48" />
          <stop offset="1" stop-color="#262769" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint2_linear_53_20"
          x1="668.363"
          x2="163.639"
          y1="536.303"
          y2="195.365"
        >
          <stop stop-color="#0F0C48" />
          <stop offset="1" stop-color="#262769" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint3_linear_53_20"
          x1="651.901"
          x2="278.946"
          y1="494.469"
          y2="242.569"
        >
          <stop stop-color="#0F0C48" />
          <stop offset="1" stop-color="#262769" />
        </linearGradient>
      </defs>
    </svg>
  ),
})

export default WormholeLogo
