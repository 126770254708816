import type { FC, ReactNode } from "react"
import React from "react"
import { Flex } from "@chakra-ui/react"

type Props = {
  bg?: string
  children: ReactNode
}
const MainLayout: FC<Props> = ({ bg = "gray.bg", children }) => (
  <Flex
    bg={bg}
    className="main-layout"
    direction="column"
    minHeight="100vh"
    w="full"
  >
    {children}
  </Flex>
)

export default MainLayout
