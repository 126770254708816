import React from "react"
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  Stack,
  Divider,
} from "@chakra-ui/react"

import {
  AdminList,
  AdminsEmptyList,
  AdminsLoading,
} from "organization/components/OrganizationAdminList"
import ErrorBoundary from "common/components/ErrorBoundary"
import type { Member, Organization } from "query/graphql"
import { OrganizationRole, useOrganizationAdminListQuery } from "query/graphql"
import { useOrganization } from "organization/providers/OrganizationProvider"
import Link from "common/components/Link"
import { ROUTES } from "common/constants/routes"

type PageOwnerAndEditorModalProps = {
  isOpen: boolean
  organization: Organization
  onClose: () => void
}

function PageOwnerAndEditorModal({
  isOpen,
  organization,
  onClose,
}: PageOwnerAndEditorModalProps) {
  const { myRole } = useOrganization()
  const { data, isLoading } = useOrganizationAdminListQuery({
    input: {
      filters: {
        organizationId: organization.id,
        roles: [OrganizationRole.Superadmin, OrganizationRole.Admin],
      },
    },
  })

  const organizationMembers = data && data.organizationMembers
  const admins = organizationMembers && (organizationMembers.nodes as Member[])

  const hasAdmins = admins && admins?.length > 0

  const hasSuperAdminRole = myRole === OrganizationRole.Superadmin

  return (
    <Modal closeOnOverlayClick isOpen={isOpen} onClose={onClose}>
      <ModalOverlay backdropFilter="blur(5px)" />
      <ModalContent
        maxWidth="600px"
        mb={{ base: 4, sm: 6, md: 8 }}
        mx={{ base: "0px", md: "auto" }}
      >
        <ModalHeader
          borderBottomColor="gray.100"
          borderBottomWidth={1}
          p={4}
          textStyle="h5"
        >
          Page owner and editors
        </ModalHeader>
        <ModalCloseButton />

        <ModalBody overflowY="auto" px={4} py={4}>
          <ErrorBoundary section="OrganizationAdminList">
            {isLoading ? <AdminsLoading /> : null}

            {!isLoading && hasAdmins ? (
              <AdminList
                admins={admins}
                showChangeRoleButton={false}
                showDeleteButton={false}
              />
            ) : null}

            {!isLoading && !hasAdmins ? <AdminsEmptyList /> : null}
          </ErrorBoundary>

          {hasSuperAdminRole ? (
            <Stack>
              <Divider />
              <Link href={ROUTES.governance.settings.index(organization.slug)}>
                <Button variant="tertiary" width="100%">
                  DAO settings
                </Button>
              </Link>
            </Stack>
          ) : null}
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default PageOwnerAndEditorModal
