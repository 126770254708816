import type { Connector } from "wagmi"
import { useAccount } from "wagmi"

import MetaMaskIcon from "ui/components/icons/MetaMaskIcon"
import CoinbaseWalletIcon from "web3/components/icons/CoinbaseWalletIcon"
import WalletConnectIcon from "web3/components/icons/WalletConnectIcon"
import RabbyIcon from "web3/components/icons/RabbyIcon"
import { ConnectorId } from "web3/constants/connectors"
import { Plug } from "ui/components/icons/font-awesome/Plug"
import PhantomIcon from "web3/components/icons/PhantomIcon"

export function useConnectorIcon() {
  const { connector } = useAccount()

  function getIcon(connector?: Connector<any, any, any>) {
    if (!connector) {
      return undefined
    }

    const { id } = connector

    if (id === ConnectorId.MetaMask && !window?.ethereum?.isPhantom) {
      return MetaMaskIcon
    }

    if (id === ConnectorId.CoinbaseWallet) {
      return CoinbaseWalletIcon
    }

    if (id === ConnectorId.WalletConnect) {
      return WalletConnectIcon
    }

    if (id === ConnectorId.Injected && window?.ethereum?.isRabby) {
      return RabbyIcon
    }

    if (
      (id === ConnectorId.Injected || id === ConnectorId.MetaMask) &&
      window?.ethereum?.isPhantom
    ) {
      return PhantomIcon
    }

    return Plug
  }

  return getIcon(connector)
}
