type SocialPreview = {
  image: string
}

// Hardcoded list of organizations and social preview details
const ORG_SOCIAL_PREVIEW: Record<string, Record<string, SocialPreview>> = {
  "https://api.tally.xyz": {
    // Arbitrum: https://www.tally.xyz/gov/arbitrum
    "2206072050315953936": {
      image:
        "https://static.tally.xyz/f9b30a1e-faa0-4d0e-bf56-efd5939265cc_original.png",
    },

    // AAVE: https://www.tally.xyz/gov/aave
    "2206072049829414624": {
      image:
        "https://static.tally.xyz/bee00b4e-2e66-43e3-9d85-83415b0e1c80_original.png",
    },

    // Compound: https://www.tally.xyz/gov/compound
    "2206072050458560433": {
      image:
        "https://static.tally.xyz/fe3f862d-c1c2-4954-8a58-170b15575d30_original.png",
    },

    // ENS: https://www.tally.xyz/gov/ens
    "2206072050458560426": {
      image:
        "https://static.tally.xyz/a2f47ca1-d016-48a5-831d-ad891ee521e2_original.png",
    },

    // Gitcoin: https://www.tally.xyz/gov/gitcoin
    "2206072049862969321": {
      image:
        "https://static.tally.xyz/8830ce37-5fe8-4cde-bf29-7887a8be9bd3_original.png",
    },

    // ZKsync: https://www.tally.xyz/gov/zksync
    "2297436623035434412": {
      image:
        "https://static.tally.xyz/5c70f347-4c02-4a57-a430-41f446fcf49f_original.png",
    },

    // Uniswap: https://www.tally.xyz/gov/uniswap
    "2206072050458560434": {
      image:
        "https://static.tally.xyz/d581b542-9153-439f-b0c4-4ad9fa6aba6c_original.png",
    },

    // Wormhole: https://www.tally.xyz/gov/wormhole
    "2323517483434116775": {
      image:
        "https://static.tally.xyz/978aefd6-ee5d-4811-b182-529d1561b295_original.png",
    },
  },
  "https://api.staging.tally.xyz": {},
}

export const getOrgSocialPreview = (organizationId: string) => {
  return (
    ORG_SOCIAL_PREVIEW?.[process.env.NEXT_PUBLIC_TALLY_API_URL]?.[
      organizationId
    ] ?? undefined
  )
}
