import { setCookie } from "nookies"
import { useQueryClient } from "@tanstack/react-query"

import type { AccountId, LoginAsSafeMutation } from "query/graphql"
import { LoginAsSafeDocument } from "query/graphql"
import { fetcher, GrpcErrorType } from "common/helpers/fetcher"
import { useSession } from "session/providers/SessionProvider"
import { useRouter } from "common/hooks/useRouter"
import { useRefetchMe } from "user/providers/MeProvider"

export const useLoginAsSafe = () => {
  const { setIsLoggedIn } = useSession()
  const { push } = useRouter()
  const refetch = useRefetchMe()
  const queryClient = useQueryClient()

  const loginAsSafe = (accountId: AccountId, redirectTo?: string) => {
    fetcher
      .gql<LoginAsSafeMutation>({
        query: LoginAsSafeDocument,
        omittedErrors: [
          GrpcErrorType.InvalidArgument,
          GrpcErrorType.PermissionDenied,
        ],
        variables: {
          accountId,
        },
      })
      .then((data) => {
        if (!data) return

        const { loginAsSafe: token } = data

        setCookie(null, "token", token, { path: "/" })

        setIsLoggedIn(true)

        if (redirectTo) push(redirectTo)

        refetch()

        const queriesToInvalidate = ["UserOrganizations", "UserGovernances"]
        queriesToInvalidate.forEach((query) =>
          queryClient.invalidateQueries([query]),
        )
      })
  }

  return { loginAsSafe }
}
