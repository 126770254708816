import {
  Modal,
  Stack,
  Button,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  Icon,
  ModalFooter,
  Skeleton,
} from "@chakra-ui/react"
import React, { useState } from "react"
import { useDebounce } from "use-debounce"

import EmptyEntity from "common/components/EmptyEntity"
import Link from "common/components/Link"
import GovernanceAvatar from "governance/components/GovernanceAvatar"
import Criteria from "common/components/Criteria"
import { ArrowRight } from "ui/components/icons/font-awesome/ArrowRight"
import { ROUTES } from "common/constants/routes"
import { useSearchDAOs } from "common/hooks/useSearchDAOs"

const DEBOUNCE_TIME = 1000

function SearchDaosModal({
  finalRef,
  isOpen,
  onClose,
}: {
  finalRef: React.MutableRefObject<null>
  isOpen: boolean
  onClose: () => void
}) {
  const [criteria, setCriteria] = useState("")
  const [enterPressed, setEnterPressed] = useState(false)
  const [debouncedCriteria] = useDebounce(criteria, DEBOUNCE_TIME)

  const shouldSearch =
    !!(debouncedCriteria.length > 2) || (enterPressed && criteria.length > 2)

  const { organizations, isLoading, isFetching } = useSearchDAOs({
    shouldSearch,
    query: enterPressed ? criteria : debouncedCriteria,
  })

  const shouldDisplaySkeleton = isLoading && isFetching

  function handleClose() {
    setCriteria("")
    onClose()
  }

  function handlePressEnter(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === "Enter") setEnterPressed(true)
  }

  return (
    <>
      <Modal finalFocusRef={finalRef} isOpen={isOpen} onClose={handleClose}>
        <ModalOverlay backdropFilter="blur(5px)" />
        <ModalContent maxW="40rem" top="120px">
          <ModalHeader p={4}>
            <Stack align="center" direction="row" spacing={3} w="full">
              <Criteria
                border="none"
                criteria={criteria}
                placeholder="Search DAO by name"
                setCriteria={(value) => {
                  setCriteria(value)
                  setEnterPressed(false)
                }}
                w="full"
                onKeyDown={handlePressEnter}
              />
              <Button
                _focusVisible={{
                  boxShadow: "none",
                  borderColor: "transparent",
                  outline: "3px solid hsla(216, 12%, 84%, 1)",
                  outlineOffset: "2px",
                }}
                bg="neutral.200"
                data-qa="searchdaosmodal-popup-esc-btn"
                size="sm"
                onClick={handleClose}
              >
                <Text color="gray.500" fontSize="12px" fontWeight="bold">
                  ESC
                </Text>
              </Button>
            </Stack>
          </ModalHeader>
          {criteria.length ? (
            <ModalBody
              borderTop="1px solid hsla(220, 17%, 93%, 1)"
              maxHeight="470px"
              overflowY="auto"
              p={4}
              tabIndex={-1}
            >
              {shouldDisplaySkeleton ? (
                <Stack spacing={2}>
                  <Skeleton h="50px" />
                  <Skeleton h="50px" />
                  <Skeleton h="50px" />
                </Stack>
              ) : (
                <>
                  {organizations && organizations.length > 0 ? (
                    <>
                      {organizations.map((org) => {
                        return (
                          <Link
                            key={`search_result_${org.id}`}
                            className="no-underline"
                            href={ROUTES.governance.id(org.slug)}
                            onClick={handleClose}
                          >
                            <Stack
                              _focusVisible={{
                                boxShadow: "none",
                                borderColor: "transparent",
                                outline: "3px solid hsla(216, 12%, 84%, 1)",
                                outlineOffset: "2px",
                              }}
                              _hover={{ bg: "neutral.100" }}
                              align="center"
                              direction="row"
                              p={3}
                              rounded="5px"
                              spacing={3}
                              tabIndex={0}
                            >
                              <GovernanceAvatar
                                size={8}
                                src={org.metadata?.icon}
                              />
                              <Text fontWeight="bold">{org.name}</Text>
                            </Stack>
                          </Link>
                        )
                      })}
                    </>
                  ) : (
                    <EmptyEntity mt={0} title="No DAOs found" />
                  )}
                </>
              )}
            </ModalBody>
          ) : null}
          <ModalFooter
            alignItems="center"
            borderTop="1px solid hsla(220, 17%, 93%, 1)"
            justifyContent="center"
            p={4}
          >
            <Link href={ROUTES.explore()} onClick={handleClose}>
              <Button
                _focusVisible={{
                  boxShadow: "none",
                  borderColor: "transparent",
                  outline: "3px solid hsla(216, 12%, 84%, 1)",
                  outlineOffset: "2px",
                }}
                data-qa="searchdaosmodal-explorealldaos-btn"
                py={1}
                variant="tertiary"
              >
                <Text
                  color="neutral.500"
                  fontSize="14px"
                  fontWeight="medium"
                  lineHeight="18px"
                  mr={1}
                >
                  Explore all DAOs
                </Text>
                <Icon as={ArrowRight} fill="neutral.500" h={4} w={3} />
              </Button>
            </Link>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default SearchDaosModal
