import type { FC } from "react"
import React, { useState, useEffect } from "react"
import { Box } from "@chakra-ui/react"
import Router from "next/router"

const HeaderTopBorder: FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    const handleRouteChangeStart = () => {
      setIsLoading(true)
    }

    const handleRouteChangeComplete = () => {
      setIsLoading(false)
    }

    Router.events.on("routeChangeStart", handleRouteChangeStart)
    Router.events.on("routeChangeComplete", handleRouteChangeComplete)

    return () => {
      Router.events.off("routeChangeStart", handleRouteChangeStart)
      Router.events.off("routeChangeComplete", handleRouteChangeComplete)
    }
  }, [])

  return (
    <>
      {isLoading ? (
        <>
          <Box
            background={
              isLoading
                ? "transparent"
                : "linear-gradient(90deg, #6DE3CE 0%, #5AAADD 29.36%, #725BFF 58.11%, #2A269F 101.44%)"
            }
            height={1.5}
            left={0}
            position="absolute"
            top={0}
            w="full"
            zIndex={1}
          />
          <div
            className="bar"
            // eslint-disable-next-line jsx-a11y/aria-role
            role="bar"
          />
        </>
      ) : null}
    </>
  )
}

export default HeaderTopBorder
