import type { ButtonProps } from "@chakra-ui/react"
import { Button } from "@chakra-ui/react"
import { useEffect, useRef } from "react"
import type { FetchSignerResult } from "@wagmi/core"
import type { Signer } from "@ethersproject/abstract-signer"
import { useAccount } from "wagmi"

import { BANNED_ADDRESSES } from "address/constants/banned"
import { useModals } from "common/hooks/useModals"
import { useSession } from "session/providers/SessionProvider"
import { useSiwe, SiweError } from "web3/hooks/useSiwe"
import { useRouter } from "common/hooks/useRouter"
import { isSameAddress } from "web3/helpers/address"
import { useSignerStore } from "web3/providers/SignerProvider"

export function useConnectAccount({
  onOpen,
  redirectTo,
  shouldPromptSiwe = true,
}: {
  onOpen?: () => void
  redirectTo?: string
  shouldPromptSiwe?: boolean
} = {}) {
  const { walletSelection } = useModals()
  const { signer } = useSignerStore()
  const { signInWithEthereum } = useSiwe()
  const shouldSignInWithEthereum = useRef(false)
  const { isLoggedIn } = useSession()
  const { address, connector } = useAccount()
  const { replace, reload } = useRouter()

  useEffect(() => {
    if (
      signer &&
      address &&
      !isLoggedIn &&
      shouldSignInWithEthereum.current &&
      shouldPromptSiwe
    ) {
      shouldSignInWithEthereum.current = false
      signInWithEthereum({
        signer,
        address,
        redirectTo,
        omittedErrors: shouldPromptSiwe ? [SiweError.SafeUnsupportedSiwe] : [],
      })
    }
    // NICO: let's leave this one as adding more generates multiple transactions
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signer])

  function connectAccount() {
    if (!address) {
      onOpen?.() ?? walletSelection.onOpen()
      shouldSignInWithEthereum.current = true
    }

    if (address && signer && !isLoggedIn) {
      signInWithEthereum({ signer, address, redirectTo })
    }
  }

  useEffect(() => {
    if (address && connector) {
      const bannedAddress = BANNED_ADDRESSES.find((bannedAddress) =>
        isSameAddress(bannedAddress, address),
      )

      if (bannedAddress) {
        connector.disconnect()

        replace({
          pathname: "/500",
        }).then(() => reload())
      }
    }
  }, [address, connector, reload, replace])

  return connectAccount
}

function ConnectAccountButton({
  shouldDisplayLabel = true,
  onOpen,
  redirectTo,
  ...buttonProps
}: {
  shouldDisplayLabel?: boolean
  onOpen?: () => void
  redirectTo?: string
} & ButtonProps) {
  const { isLoggedIn } = useSession()
  const { signer } = useSignerStore()
  const { address } = useAccount()
  const connectAccount = useConnectAccount({ redirectTo, onOpen })

  function getLabel({
    address,
    signer,
    isLoggedIn,
    shouldDisplayLabel,
  }: {
    address?: string
    signer?: FetchSignerResult<Signer>
    isLoggedIn: boolean
    shouldDisplayLabel: boolean
  }) {
    if (!shouldDisplayLabel) return null

    if (!address) {
      return "Connect wallet"
    }

    if (address && signer && !isLoggedIn) {
      return "Sign in"
    }

    return "Sign in"
  }

  return (
    <Button
      key="siwe"
      _focusVisible={{
        boxShadow: "none",
        borderColor: "gray.400",
        outline: "3px solid hsla(216, 12%, 84%, 1)",
        outlineOffset: "2px",
      }}
      data-qa="connect-wallet-button"
      fontWeight="bold"
      isDisabled={Boolean(address && signer && isLoggedIn)}
      variant="primary"
      onClick={(event) => {
        event.stopPropagation()

        connectAccount()
      }}
      {...buttonProps}
    >
      {getLabel({ isLoggedIn, shouldDisplayLabel, address, signer })}
    </Button>
  )
}

export default ConnectAccountButton
