import React from "react"
import { createIcon } from "@chakra-ui/icons"

const TwitterIcon = createIcon({
  displayName: "TwitterIcon",
  viewBox: "0 0 21 18",
  path: (
    <path
      d="M18.8444 4.60934C19.6655 3.99351 20.4045 3.25452 20.9793 2.39236C20.2403 2.7208 19.3781 2.96713 18.516 3.04924C19.4192 2.51552 20.0761 1.69442 20.4045 0.668033C19.5834 1.1607 18.6391 1.53019 17.6948 1.73547C16.8737 0.873309 15.7652 0.380645 14.5336 0.380645C12.1524 0.380645 10.2228 2.31025 10.2228 4.69145C10.2228 5.0199 10.2638 5.34834 10.3459 5.67678C6.77413 5.47151 3.57181 3.74718 1.43694 1.1607C1.06744 1.77653 0.862162 2.51552 0.862162 3.33663C0.862162 4.81462 1.60116 6.12839 2.79176 6.90844C2.09382 6.86739 1.39588 6.70316 0.821106 6.37472V6.41578C0.821106 8.5096 2.2991 10.2339 4.26975 10.6445C3.94131 10.7266 3.53076 10.8087 3.16126 10.8087C2.87387 10.8087 2.62754 10.7676 2.34015 10.7266C2.87387 12.4509 4.47503 13.6826 6.36357 13.7236C4.88558 14.8732 3.03809 15.5711 1.02638 15.5711C0.656885 15.5711 0.328443 15.5301 0 15.489C1.88854 16.7207 4.14659 17.4186 6.60991 17.4186C14.5336 17.4186 18.8444 10.8908 18.8444 5.18412C18.8444 4.97884 18.8444 4.81462 18.8444 4.60934Z"
      fill="currentColor"
    />
  ),
})

export default TwitterIcon
