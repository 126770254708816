import React from "react"
import { createIcon } from "@chakra-ui/react"

export const AngleDown = createIcon({
  displayName: "AngleDown",
  viewBox: "0 0 448 512",
  path: (
    <path
      d="M241 369c-9.4 9.4-24.6 9.4-33.9 0L47 209c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l143 143L367 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9L241 369z"
      fill="currentColor"
    />
  ),
})
