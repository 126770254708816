import type { FC } from "react"
import type { ButtonProps } from "@chakra-ui/react"
import { Button, Text } from "@chakra-ui/react"

const WalletButton: FC<
  {
    walletName: string
    onClick?: () => void
    icon: React.ReactNode
  } & ButtonProps
> = ({ walletName, icon, onClick, ...buttonProps }) => {
  return (
    <Button
      backgroundColor="transparent"
      border="gray.300"
      borderRadius={4}
      display="flex"
      height={62}
      justifyContent="space-between"
      pr={3}
      onClick={onClick}
      {...buttonProps}
    >
      <Text textStyle="body.bold.lg">{walletName}</Text>
      {icon}
    </Button>
  )
}

export default WalletButton
