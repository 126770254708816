import React from "react"
import { createIcon } from "@chakra-ui/react"

export const ArrowUpRight = createIcon({
  displayName: "ArrowUpRight",
  viewBox: "0 0 384 512",
  path: (
    <path
      d="M320 96h32v32V352v32H288V352 205.3L86.6 406.6 64 429.3 18.7 384l22.6-22.6L242.7 160H96 64V96H96 320z"
      fill="currentColor"
    />
  ),
})
