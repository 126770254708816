import Image from "next/image"
import type { FC } from "react"
import React from "react"
import type { FlexProps } from "@chakra-ui/react"
import { Flex } from "@chakra-ui/react"

import { TALLY_DEFAULT_LOGO_URL } from "common/constants/logo"

type Props = {
  src?: string | null
  size: number
}

const GovernanceAvatar: FC<Props & FlexProps> = ({
  src: srcProp,
  size,
  ...flexProps
}) => {
  ////// TODO: remove this block after we start having consistent images on governances
  //
  const src =
    srcProp === "image.png" || !srcProp ? TALLY_DEFAULT_LOGO_URL : srcProp
  //
  //////

  return (
    <Flex
      align="center"
      h={size}
      justify="center"
      minW={size}
      overflow="hidden"
      pos="relative"
      rounded="full"
      w={size}
      {...flexProps}
    >
      <Image
        alt="Governance icon"
        blurDataURL={TALLY_DEFAULT_LOGO_URL}
        height={size * 16}
        placeholder="blur"
        src={src}
        width={size * 16}
      />
    </Flex>
  )
}

export default GovernanceAvatar
