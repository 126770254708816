import { createIcon } from "@chakra-ui/react"
import React from "react"

import { extractViewBox } from "common/helpers/utils"
import type { Chain } from "query/graphql"
import { Ethereum } from "web3/components/icons/Ethereum"

const GenericNetworkIcon = (chain: Chain) => {
  const networkIcon = chain.svg
    ? createIcon({
        displayName: chain.mediumName,
        viewBox: extractViewBox(chain.svg),

        path: <g dangerouslySetInnerHTML={{ __html: chain.svg }} />,
      })
    : Ethereum // Default to Ethereum if chain.svg is not provided

  return networkIcon
}

export default GenericNetworkIcon
