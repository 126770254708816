import type { FC } from "react"
import React from "react"
import type {
  FieldInputProps,
  FormikTouched,
  FormikErrors,
  FormikValues,
} from "formik"
import { getIn } from "formik"
import type { SelectProps, FlexProps } from "@chakra-ui/react"
import {
  FormControl,
  Select,
  FormErrorMessage,
  Text,
  Stack,
  Flex,
  InputGroup,
  FormLabel,
  HStack,
  Icon,
} from "@chakra-ui/react"
import { InfoIcon } from "@chakra-ui/icons"

import CircleMark from "ui/components/icons/CircleMark"

type Props = {
  errors?: FormikErrors<FormikValues>
  touched?: FormikTouched<FormikValues>
  label: string
  name: string
  placeholder: string
  shouldShowInvalidationIcon?: boolean
  selectProps?: SelectProps
  isDisabled?: boolean
  isReadOnly?: boolean
  extraLabel?: string
}

const FormSelect: FC<Props & FieldInputProps<string | number> & FlexProps> = ({
  label,
  name,
  touched,
  errors,
  children,
  placeholder,
  value,
  selectProps,
  isDisabled = false,
  isReadOnly = false,
  extraLabel,
  shouldShowInvalidationIcon = false,
  onChange,
  ...flexProps
}) => {
  const error = getIn(errors, name)
  const touch = getIn(touched, name)
  const isTouched = touch !== undefined
  const hasError = error !== undefined

  const hasLabel = label != null
  const isInvalid = isTouched && hasError

  return (
    <Flex w="full" {...flexProps}>
      <FormControl isInvalid={isInvalid}>
        <FormLabel
          color="gray.700"
          htmlFor={name}
          mb={hasLabel ? 1 : 0}
          mr={0}
          textStyle="label"
        >
          {label}
        </FormLabel>

        <InputGroup>
          <Select
            _focus={{
              borderColor: Boolean(value) ? "purple.900" : "purple.700",
            }}
            _hover={{
              boxShadow: "outline",
              borderColor: Boolean(value) ? "purple.900" : "purple.700",
            }}
            borderColor={Boolean(value) ? "purple.900" : "gray.200"}
            borderRadius={3}
            id={name}
            isDisabled={isDisabled}
            isReadOnly={isReadOnly}
            name={name}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            {...selectProps}
          >
            {children}
          </Select>
        </InputGroup>
        {extraLabel ? (
          <HStack ml={1} mt={1}>
            <InfoIcon h={3.5} w={3.5} />
            <Text fontSize="sm">{extraLabel}</Text>
          </HStack>
        ) : null}

        {isInvalid && (
          <Stack isInline alignItems="center" spacing={1}>
            {shouldShowInvalidationIcon && (
              <Icon
                as={CircleMark}
                bg="transparent"
                color="green.500"
                h={4}
                mt={0.5}
                w={4}
              />
            )}
            <FormErrorMessage>{error}</FormErrorMessage>
          </Stack>
        )}
      </FormControl>
    </Flex>
  )
}

export default FormSelect
