import { useAccount } from "wagmi"
import { Box, Button, Icon, Stack, Text } from "@chakra-ui/react"
import type { FC } from "react"
import { useMemo } from "react"
import makeBlockie from "ethereum-blockies-base64"

import { useMe } from "user/providers/MeProvider"
import UserAvatar from "common/components/UserAvatar"
import { shortString } from "common/helpers/string"
import { useRouter } from "common/hooks/useRouter"
import { useConnectorIcon } from "web3/hooks/useConnectorIcon"
import { getDisplayName } from "user/helpers/user"
import CopyButton from "common/components/CopyButton"

type Props = {
  walletAddress: string
  redirectTo?: string
}
const UserMenuDetails: FC<Props> = ({ walletAddress, redirectTo }) => {
  const me = useMe()
  const { connector } = useAccount()
  const { reload, push } = useRouter()
  const connectorIcon = useConnectorIcon()

  const copyAddress = me?.type === "SAFE" ? me.address : walletAddress

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { address, picture, type, id, displayName } = useMemo(() => {
    if (me) {
      const { address, picture, type, id } = me
      const displayName = getDisplayName(me)

      return { displayName, address, picture, type, id }
    }

    return {
      address: walletAddress ?? "",
      picture: makeBlockie(walletAddress || ""),
      type: "",
      id: "",
      displayName: walletAddress,
    }
  }, [me, walletAddress])

  const handleDisconnectWallet = () => {
    if (!connector) return

    connector.disconnect()
    setTimeout(() => {
      if (redirectTo) {
        push(redirectTo).then(() => reload())
      } else {
        reload()
      }
    }, 1000)
  }

  return (
    <Stack align="center" direction="column" pb={2} pt={4} spacing={1}>
      <UserAvatar
        shouldShowBadge
        address={walletAddress}
        badgeIcon={
          <Box
            alignItems="center"
            backgroundColor="white"
            border="1px"
            borderColor="gray.200"
            borderRadius="50%"
            display="inline-flex"
            justifyContent="center"
            p={0.5}
          >
            <Icon as={connectorIcon} h={5} w={5} />
          </Box>
        }
        size="lg"
        src={picture}
      />

      <Stack align="center" direction="row" spacing={1}>
        <Text fontSize="md" fontWeight="medium">
          {shortString(address)}
        </Text>
        <CopyButton size={8} tooltipLabel="address" value={copyAddress} />
      </Stack>

      <Button
        px={4}
        py={1}
        variant="secondary"
        onClick={handleDisconnectWallet}
      >
        Disconnect
      </Button>
    </Stack>
  )
}

export default UserMenuDetails
