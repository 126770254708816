import { useEffect, useState } from "react"

type Values = {
  origin: string
  href: string
}

export const useLocation = (): Values => {
  const [origin, setOrigin] = useState("")
  const [href, setHref] = useState("")

  useEffect(() => {
    if (typeof window !== "undefined") {
      const { origin, href } = window.location

      if (origin) {
        setOrigin(origin)
      }

      if (href) {
        setHref(href)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    origin,
    href,
  }
}
