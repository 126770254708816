import type { FC } from "react"
import type { TagProps } from "@chakra-ui/react"
import { TagLeftIcon, Tag, TagLabel, Tooltip, Icon } from "@chakra-ui/react"

import WormholeLogo from "common/components/WormholeLogo"
import chains, { ChainId } from "web3/constants/chains"
import GenericNetworkIcon from "web3/components/icons/GenericChainIcon"

type NetworkTagProps = {
  chainId: string
  isMultiChain?: boolean
  displayOnlyIcon?: boolean
}

const NetworkTag: FC<NetworkTagProps & TagProps> = ({
  chainId,
  isMultiChain = false,
  displayOnlyIcon = false,
  ...tagProps
}) => {
  const reference = chainId as ChainId

  const chain = chains[reference]

  if (!chain) {
    return null
  }

  const isEthereum = chain.id === ChainId.EthereumMainnet

  const networkIcon = isMultiChain ? WormholeLogo : GenericNetworkIcon(chain)

  const label = isMultiChain ? "Multichain" : chain.mediumName

  return (
    <Tag py={1} rounded="full" size="md" variant="subtle" {...tagProps}>
      {displayOnlyIcon ? (
        <Tooltip label={label}>
          <Icon as={networkIcon} h={4} w={isEthereum ? 3 : 4} />
        </Tooltip>
      ) : (
        <>
          <TagLeftIcon
            as={networkIcon}
            h={4}
            marginInlineEnd="0.4rem"
            w={isEthereum ? 3 : 4}
          />
          <TagLabel
            color="purple.900"
            fontWeight="medium"
            py={1}
            textTransform="none"
            {...tagProps}
          >
            {label}
          </TagLabel>
        </>
      )}
    </Tag>
  )
}

export default NetworkTag
