import React from "react"
import { createIcon } from "@chakra-ui/icons"

const AlignLeftIcon = createIcon({
  displayName: "AlignLeftIcon",
  viewBox: "0 0 600 600",
  path: (
    <path d="M24 40H0V88H24 264h24V40H264 24zm0 128H0v48H24 424h24V168H424 24zM0 296v48H24 264h24V296H264 24 0zM24 424H0v48H24 424h24V424H424 24z" />
  ),
})

export default AlignLeftIcon
