import React from "react"
import { createIcon } from "@chakra-ui/react"

export const AngleUp = createIcon({
  displayName: "AngleUp",
  viewBox: "0 0 448 512",
  path: (
    <path
      d="M224 126.1l17 17L401 303l17 17L384 353.9l-17-17-143-143L81 337l-17 17L30.1 320l17-17L207 143l17-17z"
      fill="currentColor"
    />
  ),
})
