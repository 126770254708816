import type { FC, ReactNode } from "react"
import React from "react"
import type { FlexProps } from "@chakra-ui/layout"
import { Flex, Text } from "@chakra-ui/layout"
import { Icon } from "@chakra-ui/react"

import CircleWavesIcon from "ui/components/icons/CircleWavesIcon"

type Props = {
  description?: string
  children?: ReactNode
  title?: string
}

const EmptyEntity: FC<Props & FlexProps> = ({
  description,
  title,
  children,
  ...flexProps
}) => {
  return (
    <Flex
      align="center"
      direction="column"
      flex={1}
      justify="center"
      mt={2}
      p={6}
      {...flexProps}
    >
      <Icon as={CircleWavesIcon} h={16} mb={3} w={16} />
      {title && (
        <Text color="gray.600" textStyle="body.bold.md">
          {title}
        </Text>
      )}
      {children && (
        <Text color="gray.600" textStyle="body.bold.md">
          {children}
        </Text>
      )}
      {description && (
        <Text color="gray.600" textStyle="body.regular.md">
          {description}
        </Text>
      )}
    </Flex>
  )
}

export default EmptyEntity
