type DeviceValues = {
  base: string
  lg: string
}

type Values = {
  onLargeDevice: DeviceValues
  onLittleDevice: DeviceValues
  onAllDevices: DeviceValues
}

export const useDevice = (): Values => {
  const onLargeDevice = { base: "none", lg: "flex" }
  const onLittleDevice = { base: "flex", lg: "none" }
  const onAllDevices = { base: "flex", lg: "flex" }

  return {
    onLargeDevice,
    onLittleDevice,
    onAllDevices,
  }
}
