import { useConnect } from "wagmi"
import { InjectedConnector } from "wagmi/connectors/injected"

import { useModals } from "common/hooks/useModals"
import { useToast } from "common/hooks/useToast"

type Values = {
  activateMetamask: () => void
  activateRabby: () => void
  activateCoinbaseWallet: () => void
  activateWalletConnect: (chainId?: number) => Promise<boolean>
  activateBraveWallet: () => void
  activateGenericInjected: () => void
}

const useConnectWallet = (): Values => {
  const { toast } = useToast()
  const { walletSelection } = useModals()
  const { connect, connectors } = useConnect({
    connector: new InjectedConnector({
      options: {
        shimDisconnect: true,
      },
    }),
  })

  const activateMetamask = (): boolean => {
    try {
      if (!window?.ethereum) throw new Error("No MetaMask installed")
      connect({ connector: connectors[0] })

      return true
    } catch (error) {
      toast({
        status: "error",
        title: "MetaMask has failed",
        description: (error as Error)?.message,
      })

      return false
    } finally {
      walletSelection.onClose()
    }
  }

  const activateRabby = (): boolean => {
    try {
      if (!window?.ethereum?.isRabby) throw new Error("No Rabby installed")
      connect({ connector: connectors[3] })

      return true
    } catch (err) {
      toast({
        status: "error",
        title: "Rabby has failed",
        description: (err as Error)?.message,
      })

      return false
    } finally {
      walletSelection.onClose()
    }
  }

  const activateBraveWallet = (): boolean => {
    try {
      if (!window?.ethereum?.isBraveWallet)
        throw new Error("No Brave wallet installed")
      connect({ connector: connectors[3] })

      return true
    } catch (err) {
      toast({
        status: "error",
        title: "Brave wallet has failed",
        description: (err as Error)?.message,
      })

      return false
    } finally {
      walletSelection.onClose()
    }
  }

  const activateGenericInjected = (): boolean => {
    try {
      if (!window?.ethereum) throw new Error("No wallet installed")
      connect({ connector: connectors[3] })

      return true
    } catch (err) {
      toast({
        status: "error",
        title: "Wallet has failed",
        description: (err as Error)?.message,
      })

      return false
    } finally {
      walletSelection.onClose()
    }
  }

  const activateWalletConnect = async (chainId?: number): Promise<boolean> => {
    try {
      connect({ connector: connectors[1], chainId })
      walletSelection.onClose()

      return true
    } catch (error) {
      toast({
        status: "error",
        title: "WalletConnect has failed",
        description: (error as Error)?.message,
      })

      return false
    }
  }

  const activateCoinbaseWallet = (): boolean => {
    try {
      if (!window?.ethereum) throw new Error("No Coinbase Wallet installed")
      connect({ connector: connectors[2] })

      return true
    } catch (error) {
      toast({
        status: "error",
        title: "Coinbase Wallet has failed",
        description: (error as Error)?.message,
      })

      return false
    } finally {
      walletSelection.onClose()
    }
  }

  return {
    activateMetamask,
    activateRabby,
    activateCoinbaseWallet,
    activateWalletConnect,
    activateBraveWallet,
    activateGenericInjected,
  }
}

export { useConnectWallet }
