import React from "react"
import { createIcon } from "@chakra-ui/icons"

const CircleWavesIcon = createIcon({
  displayName: "CircleWavesIcon",
  viewBox: "0 0 73 71",
  path: (
    <svg
      fill="none"
      height="71"
      viewBox="0 0 73 71"
      width="73"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="34" cy="37" fill="#EDF2F7" r="34" />
      <mask
        height="68"
        id="mask0_3336:35780"
        maskUnits="userSpaceOnUse"
        width="68"
        x="0"
        y="3"
      >
        <rect
          fill="#F7FAFC"
          height="68"
          opacity="0.5"
          rx="34"
          width="68"
          y="3"
        />
      </mask>
      <g mask="url(#mask0_3336:35780)">
        <path
          clipRule="evenodd"
          d="M71.4251 -8.24541C76.1111 -5.25855 76.9322 0.98987 78.8898 6.1804C80.7113 11.0102 82.5548 15.7129 82.4033 20.8643C82.2251 26.9287 82.811 34.4098 77.9531 38.0413C73.0599 41.6992 66.1511 37.3444 60.0319 37.5881C54.4946 37.8085 48.5114 42.5105 43.9192 39.3963C39.2782 36.2489 41.4941 28.8541 39.4616 23.638C37.5874 18.8275 32.0542 15.3295 32.5426 10.2C33.0382 4.99341 37.9185 1.49189 41.8933 -1.90537C45.7731 -5.22122 50.0365 -7.95089 55.0359 -9.02429C60.5118 -10.1998 66.6932 -11.2616 71.4251 -8.24541Z"
          fillRule="evenodd"
          stroke="#CBD5E0"
          strokeWidth="0.8"
        />
        <path
          clipRule="evenodd"
          d="M74.3175 -14.2761C80.1441 -10.5622 81.1671 -2.79712 83.6039 3.65392C85.8706 9.65636 88.1647 15.5008 87.9788 21.9029C87.7599 29.4391 88.4917 38.736 82.4539 43.2467C76.3724 47.7901 67.7813 42.3752 60.174 42.6752C53.2898 42.9466 45.8536 48.7871 40.1431 44.9154C34.372 41.0022 37.1235 31.8135 34.5942 25.3306C32.2619 19.3523 25.3815 15.0024 25.9862 8.62819C26.5995 2.1579 32.6656 -2.19114 37.6057 -6.41151C42.4274 -10.5298 47.7267 -13.92 53.9408 -15.2516C60.7487 -16.71 68.4329 -18.0265 74.3175 -14.2761Z"
          fillRule="evenodd"
          stroke="#CBD5E0"
          strokeWidth="0.8"
        />
        <path
          clipRule="evenodd"
          d="M77.0027 -19.8764C83.8894 -15.4875 85.1017 -6.31379 87.9815 1.30787C90.6617 8.3998 93.3741 15.3052 93.1561 22.8674C92.8995 31.7705 93.7668 42.7531 86.6334 48.0801C79.4483 53.4457 69.2951 47.0462 60.3058 47.3989C52.1708 47.7176 43.3861 54.6151 36.637 50.0392C29.816 45.4153 33.0652 34.5608 30.0745 26.9015C27.3174 19.8383 19.1852 14.6978 19.8981 7.16786C20.6217 -0.475051 27.788 -5.61116 33.6243 -10.5953C39.321 -15.4598 45.5818 -19.4634 52.9253 -21.035C60.9683 -22.7552 70.0482 -24.3085 77.0027 -19.8764Z"
          fillRule="evenodd"
          stroke="#CBD5E0"
          strokeWidth="0.8"
        />
        <path
          clipRule="evenodd"
          d="M79.7906 -25.6918C87.7776 -20.6016 89.1847 -9.96555 92.5264 -1.12818C95.6359 7.09473 98.7827 15.1015 98.5316 23.8691C98.2357 34.191 99.244 46.9249 90.9728 53.0997C82.6416 59.3191 70.8662 51.8979 60.4419 52.3044C51.0088 52.6723 40.8221 60.6677 32.9948 55.3609C25.0845 49.9978 28.8497 37.4142 25.3804 28.5335C22.181 20.3438 12.7501 14.3817 13.5748 5.652C14.4127 -3.20921 22.722 -9.16254 29.489 -14.9394C36.0946 -20.5781 43.3536 -25.2188 51.8692 -27.039C61.1962 -29.0328 71.7254 -30.8318 79.7906 -25.6918Z"
          fillRule="evenodd"
          stroke="#CBD5E0"
          strokeWidth="0.8"
        />
        <path
          clipRule="evenodd"
          d="M83.1989 -32.7994C92.5307 -26.8525 94.177 -14.4286 98.0822 -4.10572C101.716 5.49962 105.394 14.8525 105.102 25.0934C104.759 37.1498 105.939 52.0238 96.2772 59.2347C86.5455 66.4978 72.7873 57.8274 60.6091 58.3001C49.5887 58.7282 37.6895 68.0648 28.5443 61.8651C19.3019 55.5993 23.6986 40.9018 19.6436 30.5281C15.9042 20.9615 4.88559 13.9962 5.84785 3.79854C6.82432 -6.55164 16.5316 -13.5038 24.4356 -20.2503C32.151 -26.8353 40.6311 -32.2552 50.5792 -34.379C61.4752 -36.7053 73.7757 -38.8047 83.1989 -32.7994Z"
          fillRule="evenodd"
          stroke="#CBD5E0"
          strokeWidth="0.8"
        />
        <path
          clipRule="evenodd"
          d="M86.917 -40.5536C97.7156 -33.6719 99.6222 -19.298 104.143 -7.35425C108.35 3.75917 112.607 14.5805 112.271 26.4287C111.875 40.3774 113.243 57.586 102.064 65.9271C90.8043 74.3286 74.8831 64.2952 60.7916 64.8406C48.04 65.3341 34.2724 76.1347 23.6893 68.9603C12.9939 61.7099 18.0789 44.7064 13.3855 32.7036C9.05701 21.6351 -3.69401 13.575 -2.58198 1.77764C-1.45329 -10.1968 9.77716 -18.2388 18.9226 -26.0428C27.8494 -33.6602 37.6611 -39.9288 49.1718 -42.3851C61.7795 -45.0757 76.0118 -47.5027 86.917 -40.5536Z"
          fillRule="evenodd"
          stroke="#CBD5E0"
          strokeWidth="0.8"
        />
        <path
          clipRule="evenodd"
          d="M90.5317 -48.0921C102.757 -40.3017 104.916 -24.0311 110.035 -10.5124C114.798 2.06691 119.619 14.3159 119.24 27.7272C118.794 43.5153 120.344 62.9937 107.69 72.4337C94.945 81.9421 76.9208 70.5837 60.9693 71.1988C46.534 71.7561 30.9503 83.9791 18.9694 75.8579C6.86059 67.6507 12.615 48.405 7.30058 34.819C2.39983 22.2901 -12.0353 13.1657 -10.7777 -0.18745C-9.50137 -13.741 3.21073 -22.8425 13.5626 -31.6747C23.6671 -40.2958 34.7734 -47.3899 47.8036 -50.1692C62.0753 -53.2134 78.1867 -55.9589 90.5317 -48.0921Z"
          fillRule="evenodd"
          stroke="#CBD5E0"
          strokeWidth="0.8"
        />
        <path
          clipRule="evenodd"
          d="M93.8365 -54.9845C107.366 -46.3631 109.758 -28.3596 115.423 -13.3996C120.695 0.520414 126.03 14.0749 125.611 28.9143C125.118 46.3845 126.835 67.938 112.833 78.3828C98.7291 88.9032 78.7832 76.3334 61.1304 77.0135C45.1562 77.6289 27.912 91.1535 14.6529 82.1657C1.25309 73.0824 7.6197 51.7869 1.7376 36.753C-3.68657 22.8891 -19.6615 12.7915 -18.2711 -1.9841C-16.8595 -16.9816 -2.79266 -27.0516 8.66223 -36.8239C19.8434 -46.3628 32.1333 -54.2118 46.5526 -57.2868C62.3457 -60.6535 80.1747 -63.6903 93.8365 -54.9845Z"
          fillRule="evenodd"
          stroke="#CBD5E0"
          strokeWidth="0.8"
        />
        <path
          clipRule="evenodd"
          d="M97.348 -62.3076C112.263 -52.8035 114.9 -32.9582 121.147 -16.4675C126.959 -1.12305 132.841 13.8183 132.381 30.1756C131.839 49.4323 133.733 73.1913 118.298 84.7035C102.751 96.2993 80.762 82.4422 61.3025 83.1908C43.6929 83.8682 24.6847 98.7751 10.0673 88.8669C-4.70528 78.8536 2.31212 55.38 -4.1731 38.808C-10.1537 23.5254 -27.7646 12.3938 -26.2326 -3.89307C-24.6778 -20.4246 -9.17121 -31.5238 3.45545 -42.2952C15.7806 -52.8082 29.3277 -61.4599 45.2232 -64.8478C62.6329 -68.5586 82.287 -71.9049 97.348 -62.3076Z"
          fillRule="evenodd"
          stroke="#CBD5E0"
          strokeWidth="0.8"
        />
        <path
          clipRule="evenodd"
          d="M101.169 -70.2771C117.592 -59.8122 120.497 -37.9626 127.376 -19.8062C133.777 -2.91181 140.255 13.5387 139.748 31.5481C139.152 52.75 141.239 78.908 124.245 91.582C107.128 104.348 82.9159 89.0899 61.4899 89.913C42.1008 90.6577 21.1714 107.069 5.07736 96.1593C-11.1882 85.1338 -3.46363 59.2901 -10.6052 41.044C-17.191 24.2182 -36.5824 11.961 -34.8966 -5.97076C-33.1855 -24.1716 -16.113 -36.3908 -2.21058 -48.2492C11.3595 -59.824 26.2756 -69.3479 43.7769 -73.0775C62.9456 -77.1614 84.5857 -80.8446 101.169 -70.2771Z"
          fillRule="evenodd"
          stroke="#CBD5E0"
          strokeWidth="0.8"
        />
      </g>
      <path
        clipRule="evenodd"
        d="M62.4014 8.7675C64.3714 9.94147 64.7115 12.4053 65.5305 14.4508C66.2927 16.3542 67.0644 18.2076 66.9957 20.2395C66.9148 22.6318 67.1544 25.582 65.1056 27.0187C63.042 28.4661 60.1374 26.7543 57.561 26.8565C55.2294 26.9485 52.7059 28.8085 50.7755 27.5842C48.8246 26.347 49.7645 23.4282 48.9138 21.3726C48.1293 19.4769 45.8025 18.102 46.0135 16.0782C46.2271 14.0241 48.2852 12.6387 49.962 11.2952C51.5986 9.9839 53.3962 8.90337 55.5021 8.47541C57.8085 8.00626 60.4119 7.58204 62.4014 8.7675Z"
        fillRule="evenodd"
        opacity="0.5"
        stroke="#CBD5E0"
        strokeWidth="0.8"
      />
      <path
        clipRule="evenodd"
        d="M64.966 2.27486C67.9701 4.21772 68.494 8.28603 69.7472 11.6652C70.9133 14.8093 72.0936 17.8707 71.9941 21.2252C71.8772 25.1742 72.2496 30.0453 69.1323 32.4123C65.9923 34.7963 61.5632 31.9638 57.6385 32.1251C54.0869 32.2712 50.2474 35.3356 47.3034 33.3099C44.3283 31.2624 45.7531 26.4463 44.4515 23.0506C43.2515 19.9192 39.7043 17.6437 40.0198 14.3033C40.34 10.9128 43.4716 8.63049 46.0223 6.41669C48.5122 4.25582 51.2479 2.47624 54.4543 1.7753C57.9675 1.0071 61.933 0.312942 64.966 2.27486Z"
        fillRule="evenodd"
        opacity="0.5"
        stroke="#CBD5E0"
        strokeWidth="0.8"
      />
    </svg>
  ),
})

export default CircleWavesIcon
