import { TALLY_DEFAULT_LOGO_URL } from "common/constants/logo"
import { getMainnetChainIds } from "governance/helpers/governance"
import type { Organization, SearchOrgsQuery } from "query/graphql"
import { useSearchOrgsQuery } from "query/graphql"

type Values = {
  organizations: SearchOrgsQuery["searchOrganization"]
  isLoading: boolean
  isFetching: boolean
}

export const useSearchDAOs = ({
  shouldSearch,
  query,
}: {
  shouldSearch: boolean
  query: string
}): Values => {
  const {
    data: searchData,
    isLoading,
    isFetching,
  } = useSearchOrgsQuery(
    {
      input: {
        name: query,
      },
    },
    {
      enabled: shouldSearch,
    },
  )

  // Build an array of mainnet chain ids
  const mainnetChainIds = getMainnetChainIds().map((chainId) =>
    chainId.toString(),
  )

  // TODO(@nicolas): The sort function is a temporary solution. Remove it one the API supports this
  const orgs = searchData?.searchOrganization
    ?.filter(
      (organization) =>
        organization.governorIds?.length > 0 ||
        organization.tokenIds?.length > 0 ||
        (organization.metadata?.icon !== null &&
          organization.metadata?.icon !== TALLY_DEFAULT_LOGO_URL),
    )
    // Filter our organizations that are not on any mainnet chain
    .filter((organization) =>
      organization.chainIds.some((chainId) =>
        mainnetChainIds.includes(chainId),
      ),
    )
    .sort((a, b) => (a.tokenOwnersCount > b.tokenOwnersCount ? -1 : 1))
    .sort(sortByLogo)

  function sortByLogo(
    a: Pick<Organization, "metadata">,
    b: Pick<Organization, "metadata">,
  ) {
    if (a.metadata?.icon && b.metadata?.icon === null) {
      return -1
    }

    if (a.metadata?.icon === null && b.metadata?.icon) {
      return 1
    }

    return 0
  }

  return {
    organizations: orgs as Values["organizations"],
    isLoading,
    isFetching,
  }
}
