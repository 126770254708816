import type { InputProps } from "@chakra-ui/react"
import { Input, Icon, InputGroup, InputLeftElement } from "@chakra-ui/react"
import type { FC } from "react"

import { MagnifyingGlass } from "ui/components/icons/font-awesome/MagnifyingGlass"

const Criteria: FC<
  {
    criteria: string
    setCriteria: React.Dispatch<React.SetStateAction<string>>
    w?: InputProps["w"]
  } & InputProps
> = ({ criteria, setCriteria, w = "auto", ...inputProps }) => {
  return (
    <InputGroup
      _focusVisible={{
        boxShadow: "none",
        borderColor: "transparent",
        outline: "3px solid hsla(216, 12%, 84%, 1)",
        outlineOffset: "2px",
      }}
      w={w}
    >
      <InputLeftElement>
        <Icon
          as={MagnifyingGlass}
          fill={criteria ? "neutral.500" : "neutral.300"}
          transition="all 200ms ease-out"
        />
      </InputLeftElement>
      <Input
        _focusVisible={{
          boxShadow: "none",
          borderColor: "transparent",
          outline: "3px solid hsla(216, 12%, 84%, 1)",
          outlineOffset: "2px",
        }}
        _placeholder={{
          color: "neutral.400",
        }}
        border="1px solid hsla(220, 17%, 93%, 1)"
        color="neutral.500"
        fontSize="14px"
        fontWeight="medium"
        lineHeight="20px"
        placeholder="Filter by name"
        tabIndex={0}
        type="text"
        value={criteria}
        onChange={(event) => setCriteria(event.target.value)}
        {...inputProps}
        w={w}
      />
    </InputGroup>
  )
}

export default Criteria
