import { OrganizationRole } from "query/graphql"

export const getOrganizationRoleLabel = (
  role: OrganizationRole,
): string | null => {
  if (Boolean(role)) {
    switch (role) {
      case OrganizationRole.Superadmin:
        return "Page Owner"

      case OrganizationRole.Admin:
        return "Page Editor"

      case OrganizationRole.Member:
        return "Member"
    }
  }

  return null
}
