import type { FC } from "react"
import type { ButtonProps, IconProps } from "@chakra-ui/react"
import { Button, Icon, Link, Tooltip } from "@chakra-ui/react"

import { EXTERNAL_ROUTES } from "common/constants/routes"
import { chainIdToChainReference } from "web3/helpers/transformers"
import { ArrowUpRight } from "ui/components/icons/font-awesome/ArrowUpRight"

type Props = {
  chainId: string
  address: string
  size: number
  iconProps?: IconProps
}

type ButtonPropsChakra = Omit<ButtonProps, "size">

const BlockExplorerButton: FC<ButtonPropsChakra & Props> = ({
  size,
  iconProps,
  chainId,
  address,
}) => {
  const chainReference = chainIdToChainReference(chainId)
  const blockExplorerUrl = EXTERNAL_ROUTES.etherscan.address(
    chainReference,
    address,
  )

  if (!blockExplorerUrl) {
    return null
  }

  return (
    <Tooltip label="View on block explorer">
      <Button
        isExternal
        alignItems="center"
        as={Link}
        bg="transparent"
        display="flex"
        h={size}
        href={blockExplorerUrl}
        justifyContent="center"
        minW={size}
        rounded="md"
        w={size}
      >
        <Icon as={ArrowUpRight} fill="gray.500" h={3} w={3} {...iconProps} />
      </Button>
    </Tooltip>
  )
}

export default BlockExplorerButton
