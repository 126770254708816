import React from "react"
import { createIcon } from "@chakra-ui/icons"

const TallyBlackT = createIcon({
  displayName: "TallyBlackT",
  viewBox: "0 0 22 30",
  path: (
    <svg
      fill="none"
      height="30"
      viewBox="0 0 22 30"
      width="22"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M17.774 16.8017V11.7876L2.85352 3.4165V1.87793L19.141 11.0158V17.5685L17.774 16.8017ZM13.9275 19.0115L12.5606 18.2447V27.3791L13.9275 28.1459V19.0115Z"
        fill="black"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M20.3893 15.3173V10.3032L5.46875 1.93212V0.393555L21.7562 9.53146V16.0841L20.3893 15.3173ZM16.5216 20.4342L15.1765 19.6854V27.0356L16.5216 27.7844V20.4342Z"
        fill="black"
        fillRule="evenodd"
      />
      <path
        d="M16.5316 12.4767V19.0295L11.3181 16.103V29.6068L5.46047 26.3183V12.818L0.244141 9.89156V3.33887L16.5316 12.4767Z"
        fill="black"
      />
    </svg>
  ),
})

export default TallyBlackT
